/* eslint-disable-next-line */
/* 관리자 페이지 CSS file */

/* 관리자 페이지 헤더 */
.m_header{
    position: fixed;
    width: 100%;
}
.m_header.seller .lnb{
    width:100% !important;
    padding: 14px 0px !important;
}

.m_header .lnb{
    width:100%;
    padding: 16px 20px;
}
.m_header .logo img {
    width: 150px;
}
.m_header .search-content{

}
.m_header .search-content .search-field{
    width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.m_header .search-content .search-field input{
    border: 0;
    border: 1px solid lightgray;
    background: #fff;
    padding: 13px;
    box-sizing: border-box;
    /* background-color: var(--snow); */
    width: 100%;
    /* border-radius: 10px; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); */
}
.m_header .search-content .search-field input:focus{
    outline: none !important;
    border: 1px solid var(--main-color);
    box-shadow: 0 0 5px var(--main-color);
}
.m_header .search-content .search-field input:focus i{

}
.m_header .search-content .search-field input:focus::placeholder{
    visibility: hidden;
}
.m_header .search-content .search-field i{
    position: absolute;
    font-size: 25px;
    color: #888888;
    right: 10px;
    cursor: pointer;
}
.Mnav{
    margin-top: 87px;
    background-color: var(--main-blue);
    padding: 20px;
    width: 13%;
    /* width: 260px; */
    position: fixed;
    overflow-y: auto;
    height: 100vh;
    float: left;
    z-index: 99;
    box-shadow: transparent 0px 0px 0px inset;
    border-right: 1px solid var(--bs-border-color);
}
.mainContents{
    margin-top: 83px;
    float: right;
    width: 87%;
    /* background-color: #f9fafb; */
    height: auto;
    min-height: 100%;
    padding: 30px;
}
.mainContents .page-tit h2{
    font-size: 20px;
    text-align: left;
    font-weight: 500;
}

.mainContents .page-tit{
    margin-bottom: 20px;
}
.mainContents .contentList{
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: wrap;
    flex-flow: wrap;
    row-gap: 30px;
    -webkit-column-gap: 16px;
    column-gap: 16px;
    margin-bottom: 48px;
}
.mainContents.f9{
    background-color: #f9fafb;
}
.contentList .itemsWrap{
    width: 100%;
}
.contentList .itemWrap{
    /* box-shadow: 0 2px 3px 0 rgba(0,0,0,.05); */
    box-shadow: 3px 3px 6px #e2e2e2, -3px 3px 6px #e2e2e2;
    transition: 0.2s;
    /* max-width: 500px; */
    border: 1px solid #ebebeb;
    width: calc(50% - 12px) !important;
    height: 100%;
    max-height: 200px;
    padding: 24px 20px;
    background: #ffffff;
    border-radius: 14px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.05));
    min-height: 200px;
}
.contentList .itemWrap .tit{
    width: 100%;
    display: flex;
    justify-content: space-between;  
}
.contentList .itemWrap .tit h2{
    font-weight: bold;
    letter-spacing: -1px;
    font-size: 20px;
}
.contentList .itemWrap .tit span{
    font-size: 13px;
    color: #888888;
    font-weight: 500;
    letter-spacing: -1px;
}
.contentList .itemWrap .tit .time{
    margin-right: 10px;
}

.contentList .itemWrap .detailWrap{
    display: flex;
    margin-top: 36px;
    gap: 30px;
    justify-content: center;
}
.contentList .itemWrap .detailWrap.col{
    flex-direction: column;
    gap: 0;
    margin-top: 0;
    align-items: end;
    gap: 10px;
}

.contentList .itemWrap .tit .reset{
    display: flex;
}
.contentList .itemWrap .tit .reset .reset_btn{
    font-size: 24px;
    color: var(--bs-gray-600);
}
.contentList .itemWrap.sbox{
    width: 32.3333% !important; 
    margin-right: 2px;
}
.contentList .itemWrap .detailWrap.col .status-box{
    align-items: end;
}

.contentList .itemWrap .detailWrap .status-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contentList .itemWrap .detailWrap .status-box p{
    font-size: 30px;
    font-weight: bold;
}
.contentList .itemWrap .detailWrap.col .status-box span{
    margin-top: 0;
}

.contentList .itemWrap .detailWrap .status-box span{
    font-size: 13px;
    font-weight: bold;
    color: var(--bs-gray-700);
    margin-top: 10px;
    letter-spacing: -1px;
}
.contentList .itemWrap.mbox{
    width: calc(66.6666% - 12px) !important;
}

.contentList .page-tit h2{
    font-weight: bold;
    letter-spacing: -2px;
    font-size: 23px;
}

/* 관리자 페이지 Nav Bar */
.category-content{

}
.category-content .category-accordion{

}
.category-content .category-accordion .title{
    cursor: pointer;
    display: flex;
    font-size: 17px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}
.category-content .category-accordion .title a{
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -1px;
    color: #000;
}
.category-content .category-accordion .title a i {
    margin-right: 0.5rem;
}
.category-content .category-accordion .title p{
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -1px;
    color: #000;
}
.category-content .category-accordion .title p i{
    margin-right: 0.5rem;
    color: var(--bs-gray-700);
}
.category-content .category-accordion .detail{
    opacity: 0;
    height: 0px;
}

.category-content .category-accordion .detail-show{
    opacity: 1;
    padding-left: 20px;
}
.category-content .category-accordion .detail-show a{
    display: block;
    font-size: 14px;
    letter-spacing: -1px;
    color: #000;
    font-weight: 500;
    margin-bottom: 10px;
}
.category-content .category-accordion .detail-show a:last-child{
    margin-bottom: 30px;    
}
/* 표 디자인 */
.sTable{
    background-color: #fff;
    border-collapse: collapse;
}
.sTable tr{
    border-bottom: 1px solid #eee;
}

.sTable th,
.sTable td{
    font-size: 14px;
    padding: 5px 10px;
    text-align: center;
    font-weight: 500;
    border: 1px solid var(--bs-border-color);
    white-space: nowrap;
}
.sTable tr th{
    background-color: #f7f7f6;
    white-space: nowrap;
    font-weight: bold;
    /* padding: 20px; */
}

.s1Table td:nth-child(1) { 
    position: sticky; 
    left: 0; 
}

.s1Table tr{
    background-color: #f7f7f6;
    border-bottom: 1px solid #eee;
}
.s1Table tr td:nth-child(1)
{
    font-size: 14px;
    font-weight: bold;
    width: 11%;
    /* padding: 14px 0px; */
    padding: 25px 0px;
}
.s1Table tr:last-child{
    border-bottom: 0;
}
.s1Table input[type=text]
{
    color: #000;
    font-weight: 500;
    width: 30%;
    border: 1px solid var(--silver-gray);
    padding: 6px 13px;
    /* margin-left: 10px; */
    font-weight: 500;
    /* border-radius: 5px; */
    border-color: var(--silver-gray);
}
.s1Table input[type=text]:focus {
     outline: none !important;
     border: 1px solid var(--main-color);
     /* box-shadow: 0 0 5px var(--main-color); */
}
.s1Table input[type=date]
{
    font-size: 13px;
    border: 1px solid var(--silver-gray);
    padding: 5px 13px;
    /* margin-left: 10px; */
    font-weight: 500;
    /* border-radius: 5px; */
    border-color: var(--silver-gray);   
}
.s1Table input[type=radio] + label{
    font-size: 14px;
    font-weight: bold;
    margin-right: 35px !important;
    margin-left: 0px !important;
}
.m-btn{
    width: 120px;
    height: 45px;
    border-radius: 2px;
    text-align: center;
    /* line-height: 28px; */
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 5px;
}
.btn-wrap{
    display: flex;
    gap:5px
}
.btn-wrap-center{
    display: flex;
    justify-content: center;
}
.m-sm-btn.w130{
   width: 130px !important; 
}
.m-sm-btn.mr5{
    margin-right: 5px;
}

.m-sm-btn{
    /* width: 83px; */
    /* width: 130px;
    height: 36px; */
    /* max-width: 83px; */
    font-size: 12px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray) !important;
    font-weight: bold;
    border-radius: 4px;
}

.m-sm-btn:hover{
    border: 1px solid var(--main-color);
    color : var(--main-color);
}

.m-sm-btn.click{
    color : var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
}
.m-sm-btn i{
    margin-right: 4px;
    font-size: 15px;
}

.m-sm-btn.blue{
    color : #fff;
    background-color: #0073e9;
    border: 1px solid #0073e9;
}
.m-sm-btn.red{
    color : #fff;
    background-color: #c43333;
    border: 1px solid #c43333;
    margin-right: 5px;
}
.m-sm-btn.black{
    background: #222;
    color: #fff !important;
}
.m-btn.m-btn-search{
    color : #fff;
    background-color: #0073e9;
    border: none;
}
.m-btn.m-btn-search.b{
    border-radius: 0;
    height: 50px;
    color: #fff;
    background: var(--main-color);
}
.m-btn.m-btn-delete{
    color: red;
    background: #fff;
    border: 1px solid;
}

/* 모달용 m-btn*/
.modal-content .m-sm-btn{
    padding: 0 24px;
    margin-right: 12px;
}
.modal-content .m-sm-btn.active{
    color: var(--main-color);
    border: 1px solid var(--main-color) !important;;
}
/* 모달용 m-btn 종료 */

.m-btn-wrap-sm{
    display: flex;
    justify-content: end;
    gap: 6px;
    margin-bottom: 20px;
    position: relative;
}
.m-btn-wrap-sm.btn-left{
    justify-content: flex-start !important;
}

.m-btn-wrap-sm .startbtn{ 
    position: absolute;
    left: 0;
}

.m-btn-wrap-v2{
    display: flex;
    justify-content:space-between;
    margin-bottom: 20px;

}
.m-btn-wrap-v2 div{
    display: flex;
    gap: 0.4rem;
}
.m-btn-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
}
.m-btn-wrap .more-btn{
    border-radius: 5px;
    background: none;
    border: 1px solid var(--silver-gray);
    position: absolute;
    bottom: 10px;
    right: 23px;
    padding: 5px 10px;
}

.m-btn-wrap button:nth-child(1)
{
    margin-right: 5px;
}
.McontentBox{
    border: 1px solid #ebebeb;
    padding: 20px;
    margin-bottom: 30px;
}
.McontentBox.m-p0{
    padding: 0 !important;
    border-radius: 5px;
}
.Mgray{
    padding: 0px 14px;
    background-color: #f7f7f6;
}
.McontentBox .subTitle{
    align-items: center;
    border-bottom: 1px solid var(--silver-gray);
    margin-bottom: 20px;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
}
.McontentBox .subTitle span:nth-child(1)
{
    font-weight: bold;
    margin-right: 0.5rem;
}
.result-wrap{
    font-weight: bold;
}
.McontentBox .subTitle .sum-s-b{
    color : var(--main-color);
    font-weight: bold;
}

.mSelectBox{
    font-size: 13px;
    padding: 8px 13px;
    /* margin-left: 10px; */
    font-weight: 500;
    /* border-radius: 5px; */
    border-color: var(--silver-gray);
}
.mSelectBox option{
    font-weight: 500;
}
.s-input-txt{
    width: 8% !important;
}
.pl13{
    padding-left: 13px;
}
.fw500{
    font-weight: 500;
    margin-left: 0.1rem;
}
.statusBadge{
    color: #fff;
    font-size: 12px;
    display: block;
    padding: 1px 6px;
    font-weight: 500;
    background-color: #999999;
}
.statusBadge.finish{
    background-color:#c43333;
}
.statusBadge.request{
    background-color: #33c481;
}
.statusBadge.modify{
    background-color: #c43333;
}
.statusBadge.complete{
    background-color: #dbc344;
}

.fixTable_wrap
{
    width: 100%;
    overflow: auto;
}
.fixTable_wrap table{
    border: 1px solid var(--silver-gray);
    white-space: nowrap;
    border-collapse: collapse; 
}
.fixTable_wrap table td input[type=checkbox] {
    width: 15px;
    height: 15px;
}
/* 
#scrollTable th:first-child {
    position: sticky;
    top: 0px;
    z-index: 99;  
} */
.sticky-col-1 {
    position: sticky;
    left: 0;
    z-index: 1;
    background-color: #fff;
}
.sticky-col-2 {
  position: sticky;
  left: 33px; /* 1열의 너비 만큼 이동 */
  z-index: 1;
  background-color: #fff;
}
.sticky-col-3 {
    position: sticky;
    left: 68px; /* 1열의 너비 만큼 이동 */
    z-index: 1;
    background-color: #fff;
}
.sticky-col-4 {
    position: sticky;
    left: 200px; /* 1열의 너비 만큼 이동 */
    z-index: 1;
    background-color: #fff;
  }
/* #scrollTable td:first-child {
    position: sticky;
    background-color: white;
    left: 0px;
    z-index: 99;
}
#scrollTable td:nth-child(2) {
    position: sticky;
    background-color: white;
    left: 30px;
}
#scrollTable td:nth-child(3) {
    position: sticky;
    background-color: white;
    left: 60px;
}
#scrollTable td:nth-child(4) {
    position: sticky;
    background-color: white;
    left: 120px;
} */
/* Registration Page 매장 등록 페이지 */
.Register-table-wrap{
    padding: 20px;
}

.Register-table-wrap .s1Table tr{
    background-color: #fff;
}
.Register-table-wrap h3{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.r-btn{
    width: 105px;
    height: 38px;
    text-align: center;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    font-size: 13px;
    /* margin-left: 10px; */
}
.r-btn:hover{
    background: #f9fafb;
}
/* 상품관리 - 상품 등록 */
.product-register-wrap{}
.product-reguster-inner{

}
.product-reguster-inner .accordion{}
.product-reguster-inner .accordion{}
.product-reguster-inner .accordion .accordion-item{
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    padding: 15px 20px;
    margin-bottom: 24px;
    border-radius: 0;
    border-radius: 5px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}
.product-reguster-inner .accordion .accordion-item .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.product-reguster-inner .accordion .accordion-item .title i{
    border: 1px solid lightgray;
    padding: 5px;
    cursor: pointer;
}
.product-reguster-inner .accordion .accordion-item .title i:hover{
    background-color: #dadada;
}

.product-reguster-inner .accordion .accordion-item .title p{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}
.product-reguster-inner .accordion .accordion-item .title p span{
    color : red;
    font-weight: bold;
}
.product-reguster-inner .accordion .accordion-item .content{
    font-size: 14px;
    padding-top: 10px;
    height: 0px;
    opacity: 0;
}
.product-reguster-inner .accordion .accordion-item .content-show{
    opacity: 1;
    margin-top: 20px;
}
.product-reguster-inner .accordion .accordion-item .mSelectBox{
    width: 50%;
    margin-left: 0px;
    font-size: 14px;
    border: 1px solid #dadce0;
}
.product-reguster-inner .accordion .accordion-item .form-input{
    width: 50%;
    padding: 4px 10px;
    font-weight: 500;
    border: 1px solid #dadce0;
}
.product-reguster-inner .accordion .accordion-item .form-input:focus{
    border : 1px solid var(--main-color);
}
.product-reguster-inner .accordion .accordion-item .form-input::placeholder{
    color : #999;
    font-size: 13px;
}
.productNameL{
    color : var(--main-color);
    margin-left: 5px;
    font-weight: 500;
}

/* 배송현황 */
.state-wrap{
    display: flex;
    margin-bottom: 20px;
}
.state-box{
    border-radius: 10px;
    width: calc(20% - 20px/5);
    height: 110px;
    margin-right: 20px;
    border: 1px solid #ddd;
    overflow: hidden;
    background: #fff;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
    max-width: 200px;
}
.state-box:hover{
    border: 1px solid var(--main-color);
    background-color: #f2f8ff;
}
.state-box.click{
    border: 1px solid var(--main-color);
    background-color: #f2f8ff;
}

.state-box .tit{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 0.6rem;
}
.state-box .dec{
    font-size: 24px;
    font-weight: bold;
}
.state-box.selected{
    border: 1px solid var(--main-color);
    background-color: #f2f8ff;
    color:#0073e9 !important;
}

.Detailed-search{
    opacity: 0;
    height: 0px; 
    display: none;
}
.Detailed-search-show{
    opacity: 1;
    display: block;
}

/* 페이지 내비게이션 버튼 */
.m-page-nav-btn{
    display: flex;
    justify-content: center;
    /* gap: 8px; */
}
.m-page-nav-btn li{
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 4px;
    cursor: pointer;
    font-weight: 500;
}
.m-page-nav-btn li:hover{
    background-color: #e1e6ec;
}
.m-page-nav-btn .cur{
    background-color: #0073e9;
    color : #fff;
}
.m-page-nav-btn .cur:hover{
    background-color: #0073e9;
}
.m-page-nav-btn button{
    background: none;
    /* border: 1px solid var(--main-color); */
    border: none;
    color: var(--main-color);
    border-radius: 5px;
}

/* 카테고리 관리 */
.categbox{
    display: inline-block;
    padding: 10px 10px 30px 0px;
}
.kwutOX{
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 16px; */
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(228, 228, 228);
    z-index: 5;
    width: 230px;
    padding: 10px;
    height: 300px;
    overflow: auto;
}
.kwutOX::-webkit-scrollbar {
    width: 8px;
}
.kwutOX::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
}
.kwutOX::-webkit-scrollbar-track {
    background-color: #fff;
}

.ctit{
    font-size: 15px;
    font-weight: 600;
    color: #111;
    line-height: 1.4;
    margin: 0.5rem 0;
}

.fBSFej > button {
    color: rgb(0, 0, 0);
    width: 100%;
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.5px;
    text-align: initial;
    border: 0;
    background: none;
    cursor: pointer;
}
.fBSFej > button:hover{
    background-color: #f7f7f6;
}

.mrbtn{
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    border: 1.2px solid rgb(212, 212, 212);
    margin-right: 4px;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    vertical-align: middle;
    outline: none !important;   
}
.ml7{
    margin-left: 7px !important;
}


.filebox label {
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    padding: .5em .75em;
    color: #222;
    line-height: normal;
    vertical-align: middle;
    background-color: #fdfdfd;
    cursor: pointer;
    border: 1px solid var(--silver-gray);
    border-radius: .25em;

  }
  
  .filebox input[type="file"] { 
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0;
  }
  .essential{
    display: inline-block;
    width: 3px;
    height: 3px;
    background: red;
    margin-left: 7px;
    border-radius: 5px;
    margin-top: 5px;
    vertical-align: top;
  }
  .mSelectBox:focus{
    outline: none !important;
    border: 1px solid var(--main-color);
  }
  .mSelectBox option {
    color: black;
    font-weight: 500;
    padding: 3px 0;
  }
  .mr6{
    margin-right: 6px !important;
  }

  /* 매장관리 > 매장등록 > 직원추가 */
  .ep10{
    padding: 10px 0;
  }
  .employeeList{
    display: flex;
    margin-top: 10px;
  }
  .employeeList li{
    border-radius: 14.5px;
    border: solid 1px #5494f5;
    background-color: #fff;
    color: #5494f5;
    font-weight: 500;
    position: relative;
    width: auto;
    height: 32px;
    padding-top: 3px;
    padding-left: 17px;
    margin-right: 0.3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-right: 8px;
  }
  .employeeList li button{
    font-weight: 500;
    background: none;
    border: none;
    color: #5494f5;
    cursor: pointer;
  }
  .addEmployeeBtn{
    width: 76px;
    height: 36px;
    text-align: center;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    font-size: 13px;
    margin-left: 10px;
}
.addEmployeeBtn:hover{
    background: #f9fafb;
}

.filename{
    color : #5494f5;
    font-weight: bold;
    margin-left: 10px;
}

.categoryinput{
    border: 1px solid var(--silver-gray);
    padding: 6px 13px;
    font-weight: 500;
    border-color: var(--silver-gray);
    width: 20%;
}

.c-addbtn {
    width: 93px;
    height: 39px;
    border-radius: 2px;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    background-color: #0073e9;
}

.cTable td{
    padding: 20px 10px;
}
.selectCategoryText{
    color : var(--main-color);
    font-weight: bold;
    letter-spacing: -0.4px;
}

.category-wrap div{
    display: flex;
    justify-content: space-between;
    align-content: center;
}
.category-wrap h2{
    cursor: pointer;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: -1px;
    color: #000;
    margin-bottom: 20px; 
}
.category-wrap ul{
    margin-left: 8px;
    margin-bottom: 24px;
}
.category-wrap ul li {
    margin-bottom: 10px;
}
.category-wrap ul li a{
    cursor: pointer;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -1px;
    color: #000;   
    margin-bottom: 15px;
}
.category-wrap ul li a:hover{
    color : var(--main-color);
}
.category-wrap ul li.active a{
    color : var(--main-color);
}

/* 관리자 페이지 검색 필드 */
.search-wrap{
    padding: 20px;
    background-color: #f7f7f6; 
    border-radius: 4px 4px 0px 0px;
}
.McontentBox .search-field{    
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
}
.McontentBox .search-field.checkbox-wrap{
    margin: 20px 0px;
}

.search-field.division select{    
    width: 10%;
}
.search-field.range div:nth-child(2){
    width: 20%;
}
.search-field.range div:nth-child(2) span{
    font-weight: 500;
}
.search-field div:nth-child(1){
    width: 10%;
}
.search-field div:nth-child(2){
    /* width: 40%; */
    width: 50%;
    display: flex;
    gap: 10px;
    align-items: center;
}
.search-field b{
    display: block;
    font-weight: bold;
    font-size: 14px;
}
.search-field input:not(input[type=radio]), .search-field select{
    font-size: 14px;
    border: 1px solid #dadada;
    padding: 6px;
    /* width: 100%; */
    /* margin-bottom: 5px; */
    font-weight: 500;
}
.search-field input:not([type="radio"]):not([type="checkbox"]) {
    flex: 3 1;
}


.search-field select{
    flex: 1;
}

.search-field input:not(input[type=radio]):focus, 
.search-field select:focus{
    border: 1px solid var(--main-color);
}


.McontentBox .search-field.radiobox{
    margin-top : 20px;
}
.search-field.word{

}
.search-field.select {
    padding: 9px 0px;
}
.search-field.select input:not(input[type=radio]){
    flex: 2;
}
.search-field.select div:nth-child(2){
    gap:0px;
}
.search-field.select div:nth-child(2) input[type=radio] + label 
{
    font-size: 15px;
}
.search-field .SearchRadio{

}
.search-field .SearchRadio input[type="radio"] {
    width: 18px;
    height: 18px;
}
.search-field .SearchRadio input[type=radio] + label{
    margin: 0;
}

.filebox{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0;
}

.error{
    border: 1px solid red;
}
.active-button{
    /* width: 100px; */
    width: 130px;
    height: 36px;
    font-size: 13px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    background: #fff;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid var(--main-color);
    color : var(--main-color);
}

.radioBox{
    display: flex;
}

.McontentBox h2{
    font-weight: bold;
    letter-spacing: -1.2px;
    font-size: 18px;
    margin-bottom: 10px;
}
.settingSubTitle
{
    font-weight: bold;
    margin-right: 0.5rem;
}
.radio-box{
    display: flex;
    align-items: center;
    margin-top: 20px;
}
.radio-box input[type=radio]{
    width: 20px;
    height: 20px;
}
.select-box{
    margin-top: 10px;
    display: flex;
    gap: 10px;
}
.setting-btn-box{
    display: flex;
    justify-content: center;
}
.setting-tab-buttons{
    display: flex;
    gap:10px;
    margin-bottom: 20px;
}
.mt30{
    margin-top: 30px;
}
.policy-input{
    width: 100%;
    height: 300px;
    resize: none;
    border: 1px solid var(--silver-gray);
    padding: 10px;
    margin-top: 1rem;
}
.policy-input::placeholder{
    font-weight: 500;
    color : #999999;
}
.authorityTd{
    display: flex;
    gap: 1px;
}
.stch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.stch input[type=checkbox] {
    width: 20px;
    height: 16px;
}
.stch input[type=checkbox] + label{
    color : #000;
    font-weight: 500;
    margin-left: 0.1rem;
}
.smBtn{
    border: none;
    border-radius: 5px;
    padding: 10px 13px;
    color: #fff;
    font-weight: bold;
    background: rgba(0, 0, 0, 0.25);
}
.admin-add-box{
    display: flex;
    gap : 5px;
    margin-top: 10px;
}
.admin-add-input{
    width: 20%;
    border: 1px solid var(--bs-gray-400);
    padding: 6px 10px;
    border-radius: 5px;
}
.admin-add-button{
    border: none;
    border-radius: 5px;
    padding: 4px 20px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    background: var(--main-color);
}
.InputError{
    color : red;
    font-size: 13px;
    font-weight: 500;
}
.confirmed{
    color : var(--main-color);
    font-weight: 500;
    font-size: 13px;
}

.nt{
    color : var(--main-color);
    font-weight: 500;
}

.filter-select{
    width: 200px; 
}
.filter-select option{
    font-weight: 500;
}

/*  */
.AddOptionWrap{
    border-top: 1px solid #eaecee;
    border-bottom: 1px solid #eaecee;

    padding: 20px 0px;
}
.AddOption{
    display: flex;
    gap : 20px;
}
.AddOption .OptionTit{
    flex : 1;
    width: 0% !important;
    padding: 2px 5px !important;
    font-weight: 500;
    border: 1px solid #dadce0;
}

.AddOption .OptionSub{
    flex : 5;
    width: 0% !important;
    padding: 0px !important;
    font-weight: 500;
    border: 1px solid #dadce0;
}

.btn {
    
}
.categorySearch .search-field{
    width: 80%;
    position: relative;
}
.categorySearch .search-field input {
    border: 0;
    border: 1px solid lightgray;
    background: #fff;
    padding: 10px !important;
    box-sizing: border-box;
    /* background-color: var(--snow); */
    width: 100%;
    /* border-radius: 10px; */
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); */
}
.categorySearch .search-field i {
    position: absolute;
    font-size: 25px;
    color: #888888;
    right: 13px;
    top: 9px;
    cursor: pointer;
}
.btn-sm{
    font-size: 13px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 4px;
}
.btn-sm.btn-danger{
    border: 1px solid #e25147;
    color: #e25147;
    background-color: #fff;
}

.Addinfo{
    display: flex;
    gap: 10px;
    padding: 5px 0;
}
.Addinfo input[type=text]{
    border: 1px solid lightgray;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    font-weight: 500;
}
.Addinfo input[type=text]:focus{
    border: 1px solid var(--main-color);
}
.Addinfo input[type=text]::placeholder{
    font-weight: 500;
}
.Addinfo input[type=text]:first-child{
    flex: 2;
}
.Addinfo input[type=text]:nth-child(2){
    flex: 7;
}
.Addinfo button{
    border-radius: 2px;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    background-color: var(--silver-gray);
    flex: 1;
    font-size: 14px;
}
.total-price{
    font-weight: bold;
    margin: 10px 0;
}
.notice-text{
    color : var(--main-color);
    font-weight: 500;
    line-height: 20px;
    margin-top: 20px;
    letter-spacing: -1px;

}

/* ProductImageUpload.js  */
.productImageUploader{
    display: flex;
    gap: 10px;
    margin-top: 20px;
}
.fileInputLabel {
    display: inline-block;
    position: relative;
    width: 180px;
    height: 180px;
    border: 1px dashed #bbb;
    border-radius: 4px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
}

.fileInputLabel:hover {
    background-color: #f5f5f5;
}
.fileInput {
    display: none;
}
  
.imagePreviewContainer {
    width: 100%;
    height: 100%;
}
  
.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
  
.imagePlaceholder {
    font-size: 12px;
    color: #999;
    margin-top: 35px;
}

.imageUploadDescription{
    text-align: center;
    font-weight: 500;
}
.SearchTags{
    border-top : 1px solid var(--silver-gray);
    padding-top: 20px;
    margin-top: 20px;
}
.Searchinfo{
    display: flex;
    gap: 10px;
    width: 30%;
}

.Searchinfo input[type=text] {
    border: 1px solid lightgray;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    flex: 3;
}

.Searchinfo button {
    border-radius: 2px;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    background-color: var(--silver-gray);
    font-size: 14px;
    flex: 1;
}
.ProductFilterWrap{
    border-top: 1px solid var(--silver-gray);
    padding-top: 20px;
    margin-top: 20px;
}
.product-attribute-text{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}
.product-attributes{
    display: flex;
    gap: 10px;
}
.product-attributes-item{
    display: flex;
    justify-content: center;
    vertical-align: center;
    margin-bottom: 20px;
}
.product-attributes-item input[type=checkbox]
{
    width : 20px;
    height: 20px;
    margin-right: 5px;
}

.product-attributes-item label {
    font-size: 15px;
    font-weight: 500;
    display: flex;
}

.product-info table{

}
.product-info table tr{ 
    border-bottom: 1px solid #ededed;
}
.product-info table .Nobr{ 
    border-bottom: 0px solid #ededed !important;

}
.product-info table th{
    width: 15%;
    padding: 20px 0;
    font-weight: bold;
}
.product-info table td{
    padding: 20px 0;
    font-weight: bold;  
}
.product-info input[type=text] {
    border: 1px solid lightgray;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    width: 100%;
}
.product-info input[type=text]:focus{
    border: 1px solid var(--main-color);
}
.radioBtn-wrap-col{
    display: flex;
    flex-direction: column;
    gap:10px;
    width: 50%;
}
.radioBtn-wrap-col label{
    font-weight: 500;
    margin-right : 1rem;;
}

.radioBtn-wrap-col span{
    font-weight: 500;
}
.radioBtn-wrap-col input[type=text]{
    width: 30% !important; 
    margin-right: 0.5rem;
}

.radioBtn-wrap{
    display: flex;
    align-items: center;
    gap : 20px
}

.radioBtn-wrap label
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}
.radioBtn-wrap-col input[type=radio],
.radioBtn-wrap input[type=radio]
{
    transform: scale(1.5);
    margin-right: 10px;
}
.radioBtn-wrap .input-sm{
    width: 10% !important;
}

.bgray5{
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 7px 5px;
}
.textarea-box {
    width: 80%;
    height: 80px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    padding: 10px 0 0 10px;
}
.textarea-box::placeholder{
    font-weight: 500;
}
.td-flex{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0px;
    justify-content: space-evenly;
}
.Table_wrap{
    margin-top: 20px;
}
.ProductInfoNotice-Wrap{
    border-top: 1px solid #eaecee;
    padding-top: 20px;
    margin-top: 20px;
}
/* 모달 백그라운드 */
.modal {
    position: fixed; /* 화면 고정 */
    z-index: 9999; /* 다른 요소보다 앞에 표시 */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4); /* 반투명한 검은색 배경 */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
/* 모달 컨텐츠 */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3); /* 그림자 효과 */
    position: relative;
}
.modal-content-box{ 
    width: 100%;
    height: 50%;
    border: solid 1px #999;
    border-radius: 5px;
    background-color: #f7f7f6;
    padding: 10px;
} 

/* 모달 닫기 버튼 */
.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
}
  
  /* 모달 닫기 버튼 마우스 호버 시 스타일 */
.close:hover {
    color: #ff5252; /* 빨간색 */
}

.modal-content.ProductDetailModal{
    width: 50% !important;
    height: 300px !important;
}

.modal-content.ExtraOptionModal{
    width: 28% !important;
    height: 450px !important;
}
.modal-content.CenterModal{
    width: 50% !important;
    /* height: 500px !important; */
}

.modal-content.ReviewWModal{
    width: 37% !important;
    height: 80%!important;
}

.modal-tit{
    font-weight: bold;
    font-size: 15px;
}
.modal-content textarea{
	width:100%; 
	height:300px; 
    resize:none;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 5px;
}
.modal-content textarea:focus{
    border : 1px solid var(--main-color);
}
.modal-button-wrap{
    display:flex;
    justify-content: center;
}
.radio-wrap{
    display: flex;
    width: 100% !important;
}
.radio-wrap input[type=checkbox] {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
.radio-wrap.ml-2{
    margin-left: 20px;
}  

.radio-wrap label {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222;
    font-weight: 500;   
    margin-bottom: 10px;
  }

  /* 동그란 버튼을 위한 스타일 */
.expand-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
  .expand-btn i {
    transition: all 0.3s ease-in-out;
    transform: rotate(0);
  }
  
  /* 새로운 테이블을 위한 스타일 */
.sub-table {
    margin-left: 2rem;
    /* display: none; */
}

button.option-active {
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}

.apply-option{
    display: flex;
    width: 13%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background: var(--main-color);
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
}
.apply-option i{
    margin-right:5px;
}

.TagList{
    display : flex;
    gap : 10px ;
    margin-top : 30px;
}


.TagList span{
    border: 1px solid var(--main-color);
    border-radius: 30px;
    padding: 3px 20px;
    font-weight: 500 ;
    color : var(--main-color);
    
}

.TagList span button{
    background : none;
    border : 0;
    color : var(--main-color);
    font-size : 20px;
}

.deleteImageButton {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  .addOption{
    font-size: 13px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 4px;
}
.reset-options{
    font-size: 13px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    color: var(--main-color);
    background: #fff;
    border: 1px solid var(--main-color);
    font-weight: bold;
    border-radius: 4px;  
    margin-top: 30px;
}

.checkbox-item{

}
.checkbox-item input[type=checkbox]
{
    
}

/* Admin Login Page */
#Adminlogin{
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; 
}
.Adminlogin{
    width: 450px;
    min-width: inherit;
    max-width: 450px;
    background-color: #fff;
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.1);
    padding: 50px;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 48px 0px;
}
.Adminlogin.signup{
    width: 1080px;
    max-width: 1080px;
}
.Adminlogin form{
    width: 100%;
}
.Adminlogin img{
    width: 180px;
    margin-bottom: 20px;
}
.Adminlogin h1{
    font-size: 1.2em;
    margin-bottom: 11px;
    font-weight: 400;
    color: #000;
}

.Adminlogin .input-field{
    width: 100%;
}

.Adminlogin .input-field input{
    width: 100%;
    padding: 25px 0 25px 10px !important;
    border-radius: 5px;
    border: 1px solid #E2E2E2;
    transition: 0.5s;
    margin: 5px 0;
    height: 0px !important;
    line-height: 0 !important;

    /* padding: 5px 0px 5px 5px; */
}
.Adminlogin .input-field input::placeholder{
    color : var(--bs-gray-700) !important;
    font-weight: 500;
    font-size: 14px !important;
}
.Adminlogin .input-field input:focus{
    border: 1px solid var(--main-color);
}
.Adminlogin .button-field{
    width: 100%;
}
.Adminlogin .button-field button{
    font-weight: 500;
    font-size: 17px;
    padding: 15px;
    width: 100%;
    border-radius: 0px;
    margin-top: 10px;
    border: none;
}
.Adminlogin .button-field .login-button{
    background-color: var(--main-color);
    color : #fff;
}

.Adminlogin .button-field .signup-button{
    background-color: #fff ;
    color : var(--main-color) ;
    border: 1px solid var(--main-color);
    font-weight: bold;
    border-radius: 3px;
    font-size: 16px;
}
.Adminlogin .add-info{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.Adminlogin .add-info a{
    font-weight: bold;
    color: var(--main-color);
    margin-right: 10px;
    position: relative;
}
/* .Adminlogin .add-info a:first-child::after{
    content: '';
    background-color: lightgray;
    height: 10px;
    width: 1px;
    position: absolute;
    right: 0;
    top : 9px; 
} */
.Adminlogin .or-text{
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    font-weight: 500;
    margin-top: 40px;
    color: gray;
}
.Adminlogin .or-text::before,
.Adminlogin .or-text::after{
    content: '';
    background-color: lightgray;
    height: 1px;
    width: 30%;
    position: absolute;
    top : 9px;
}
.Adminlogin .or-text::before{
    left: 0;
}

.Adminlogin .or-text::after{
    right: 0;
}

.ProductDetailWrap{
    border : 1px solid var(--silver-gray);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;   
}

.ProductDetailWrap button{
    border-radius: 2px;
    border: none;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    padding: 12px 30px;
    background-color: #0073e9;
    font-size: 15px;
}

.modal-content.ProductDetail-Editor-Modal{
    width: 50% !important;
    height: 600px !important;
}

.product-select {
    height: 40px;
    width: 200px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color : #000;
    font-weight: 500;
}
.sm-text-wrap input[type=text]
{
    border: 1px solid lightgray;
    height: 35px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    width: 200px;
}
.sm-text-wrap span{
    font-weight: bold;
    margin-left: 10px;
}
.checkbox-wrap{
    display: flex;
    align-items: center;
}
.checkbox-wrap input[type=checkbox]
{
    width: 15px;
    height: 15px;
    margin-right: 10px;
}
.checkbox-wrap label{
    font-weight: 500;
}

.CheckboxWrap{
    display: flex;
    gap:1px !important;
    width: 70% !important;
}
.CheckboxWrap.m{
    width: 30% !important;
}
.CheckboxWrap.l{
    width: 56% !important;
}
.CheckboxWrap.w30{
    width: 31% !important;
} 

.CheckboxWrap p{
    margin-right: 20px;
}
.CheckboxWrap .checkbox{
    display: inline-flex;  
    align-items: center;
}

.CheckboxWrap div
{
    width: 100% !important;
}
.CheckboxWrap input[type="checkbox"] {
    width: 15px;
    height: 15px;
}
.CheckboxWrap label
{
    display: flex;
    align-items: center;
}

.selectWrap{
    display: flex;
    gap : 10px;
}
.TBWrap{
    display: flex;
    gap : 5px;  
}
.expand-btn{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #aeafb4;
    text-align: center;
    font-size: 16px;
    border: 1px solid #aeafb4;
    line-height: 11px;
}
.t-s-btn{
    height: 36px;
    font-size: 13px;
    border-radius: 2px;
    text-align: center;
    line-height: 28px;
    color: #222;
    background: #fff;
    border: 1px solid var(--silver-gray);
    font-weight: bold;
    border-radius: 4px;
}
.t-s-btn.bb{
    color: var(--main-color);
    border: 1px solid var(--main-color);
}
.t-s-btn.impossible{
    background: var(--silver-gray);
    color: #fff;
}
.t-s-btn.impossible:hover{
    border: 1px solid var(--silver-gray);
    cursor:default;
}

.t-s-btn:hover{
    border: 1px solid var(--main-color);

}
.empty-input {
    border: 1px solid red !important;
}
  
.blueLink{
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.t-product-image{
    max-width: 200px; 
    max-height: 200px; 
}

.t-product-image img {
    width: 100%; 
    height: auto; 
  }

  .parent-container{
    display: flex;
    justify-content: center;
  }

  /* .skeleton-placeholder {
    width: 100%;
    height: 200px;
    background-color: #eaeaea;
    border-radius: 4px;
  } */


.radio-button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}
  
.radio-button-container label {
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-right: 10px;
}

.radio-button-container input[type="radio"] {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.resultsCount-wrap{
    display: flex;
}
.resultsCount-wrap p{
    font-weight: bold;
}

.xi-pen{
    cursor: pointer;
    margin-left : 0.5rem;
}

.cart-icon{
    position: relative;
}
.cart-icon .cart-badge{
    position: absolute;
    display: inline-block;
    border-radius: 50%;
    padding: 8px;
    bottom: 17px;
    right: 4px;
    color: #fff;
    line-height: 1.55em;
    text-align: center;
    width: 9px;
    font-weight: bold;
    font-size: 1px;
    height: 12px;
    /* box-shadow: 1px 1px 5px rgba(0,0,0, .2); */
    background-color: red;
}
.castext{
    cursor: pointer;
    font-weight: 500;
    margin-top: 0.9rem;
}
.castext:hover{
    color: var(--main-color);
    font-weight: bold;
}
.prd-add{    
    border-top: 1px solid #ccc;
    /* background: #f8fcfe; */
    padding: 10px;
    font-weight: 400;
    font-size: 14px;
    color: #000;
    font-family: "Noto Sans KR";
    overflow: hidden;
  
}
.prd-amount-wrap{
    display: flex;
    justify-content: space-between;
}
.amount-box{
    float: right;
}

.amount-box .amount {
    position: relative;
    width: 102px;
    border: 1px solid #ccc;
    float: left;
}
.amount-box .amount .amount-down {
    top: auto;
    bottom: 0;
}
.del_item_quantity, .add_item_quantity {
    display: block;
    float: left;
    width: 25px;
    height: 25px;
    line-height: 23px;
    text-align: center;
    font-weight: 500;
    color: #004303;
    background: #fff;
    cursor: pointer;
}
.prd-add .amount-box .amount input {
    
    float: left;
    width: 50px;
    height: 25px;
    border: none;
    text-align: center;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
}
.prd-amount-txt{
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
}
.prd-amount-txt .prd-name{
    font-weight: bold;
}
.prd-remove{
    cursor: pointer;
}
.xi-close{
    cursor: pointer;
}
.select-categorys{
    display: flex;
    gap: 5px;
}
.select-categorys span{
    color: var(--main-color);
    font-weight: 400;
    letter-spacing: -0.4px;
}
.btn_modify{
    display: block;
    font-weight: 500;
    background-color: #fff;
    margin-top: 3px;
    padding: 0 12px;
    line-height: 26px;
    color: #424242;
    border-radius: 4px;
    border: 1px solid #d5d9dc;
    color: #333333;
    -ms-flex-item-align: start;
    align-self: start;
}
.m_btn_area{
    display: flex;
    min-width: 0;
    position: absolute;
    bottom: 0;
    width: 90%;
    margin-bottom: 10px;
}
.m_btn_area button{
    margin: 0 2px;
    font-size: 14px;
    line-height: 40px;
    border: 1px solid #d5d9dc;
    /* color: #333333; */
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    background-color: #fff;
    border-radius: 2px;
    color: #000;
    font-weight: 400;
}
.m_btn_area .btn_confirm{
    background-color: var(--main-color) !important;
    color: #fff;
}

.cart-vendor{
    padding-bottom: 20px;
    border-bottom: 2px solid #000;
    margin-bottom: 30px;
}

.cart-vendor .vendor_name{
    font-size: 1.2rem;
}

.carttotalp{
    font-size: 1.2rem;
}

/* 드롭다운 버튼 화살표 제거 커스텀 */
.custom-dropdown .btn.show{
    border-color: #fff !important;
}
.custom-dropdown .dropdown-toggle::after {
    display: none !important;
}


.categoryCheckbox{
    margin-right: 5px;
    -ms-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
}
#addModalInput select,
#addModalInput input{
    border: 1px solid var(--silver-gray);
    padding: 11px 28px 11px 15px;
    font-weight: 500;
    border-radius: 5px;
    margin: 8px 0;
}
.modal-90w {
    max-width: none!important;
    width: 90%;
}
.invoice-file-upload-wrap{
    margin: 2rem 0;
}
.invoice-file-upload-wrap p{
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.invoice-file-upload-box{
    border: 1px dotted var(--silver-gray);
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.memoinput-box{
    border: 1px solid var(--silver-gray);
    width: 100%;;
}
.org_price{
    color: #777;
    text-decoration: line-through;
}
.c713{
    color: #777;
    font-size: 13px;
}
.total_dp:before {
    display: inline-block;
    width: 6px;
    height: 6px;
    margin: 5px 2px 0 0;
    border: 2px solid #ddd;
    border-width: 0 0 2px 2px;
    vertical-align: top;
    content: '';
}
.s-review-imgs{
    display: flex;
    gap: 1px;
}
.s-review-imgs img{
    width: 50px;
    height: 50px;
}
.CheckboxWrap2{
    display: flex;
    width: 60% !important;
    gap:15px !important;
}
.CheckboxWrap2 p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.wsg1{
    border: 1px solid var(--silver-gray);
    padding: 6px 13px;
    width: 100%;
}
.preview-wrap{
    border: 1px solid #999999;
    padding: 10px;
    border-radius: 5px;   
}

.form-input2{
    width: 50%;
    padding: 4px 10px;
    font-weight: 500;
    border: 1px solid #dadce0;
}