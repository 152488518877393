/* Font Icon CDN */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.1/font/bootstrap-icons.css");

/* Font Family CDN */
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

@font-face {
  font-family: 'NEXON Lv2 Gothic';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-04@2.1/NEXON Lv2 Gothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


:root {
  /* Font Family */
  --primary-kr: 'NanumSquare', sans-serif;
  --primary-en: 'Roboto', sans-serif;
  --logo-en: 'Bree Serif', serif;
  --secondary-kr: 'NEXON Lv2 Gothic';

  /* Text Color */
  --dark-black: #333333;
  --light-black: #555555;
  --dark-gray: #777777;
  --light-gray: #999999;
  --silver-gray: #C0C0C0;
  --gloudy-gray: #EEEEEE;

  /* Object Color */
  --yellowgreen: #BBD94E;
  --orange : #fe8440;
  --gold: #F2E635;
  --beige: #F2E8D5;
  --lightpink: #F2B999;
  --crimson: #F2444E;
  --limegreen: #2ECC71;
  --royalblue: #346AFF;
  --turquoise: #72E7E1;
  --snow: #F5F5F5;
  --gainsboro: #DCDCDC;
  --fuschia: #FCDDEC;
  --light-beige: #F2E8D5;
  --main-color : #0b6daa;
  --main-color2 : #0B6DAA;
  /* For Big Size Mobile & Tablet Device */
  --slide-basis: 375px;
}