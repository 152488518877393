* {
  box-sizing: border-box;
  outline: none;
}
a {
  text-decoration: none;
  color: inherit;
}
h1,h2,h3,h4,h5,h6 {
  margin-top: 0;
  font-weight: 500;
}
  
/* Heading Font Size */
h1 {
  font-size: 28px;
}
h2 {
  font-size: 22px;
  }
h3 {
  font-size: 18px;
}
p{
  margin: 0;
}
input,
button,
select {
  outline: none;
}
ul{
  list-style: none;
}
  
html, body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, img {
  margin: 0;
  padding: 0;
}
table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  border-collapse: collapse;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: #333;
  text-decoration: none;
}
.gray_06 {
  color: #999 !important;
}
.Mt25 {
  margin-top: 25px !important;
}
.list_dot_01 {
  line-height: 1.4;
}
input::placeholder {
  color: var(--bs-gray-600);
  font-weight: 500;
}

select option {
  font-weight: 500;
}
label{
  font-weight: 500; 
}
input[type="text"],
input[type="password"] {
  font-weight: 500;
}
a,
textarea,
span,
p{
  font-weight: 500;
}

/* 공통 */
.error_message{
  color: red;
}
.error-input {
  border: 1px solid red !important;
}
.b-btn{
  color: #fff !important;
  background-color: #0073e9 !important;
  border: none !important;
}
.w100{
  width: 100% !important;
}
.w50{
  width: 50% !important;
}
.w80{
  width: 80% !important;
}
.w30{
  width: 30% !important;
}
.w10{
  width: 10% !important;
}
