/* Font Family CDN */
@import url('https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css');

* {
    box-sizing: border-box;
    font-family: var(--primary-kr);
    font-weight: 300;
    font-family: 'NanumSquare',sans-serif;
}

body {
    font-size: 15px;
    line-height: 1.5em;
    font-weight: 400;
    color: #000;
    font-family: 'NanumSquare',sans-serif;
    margin: 0;
}
/* container 정의*/
.container{
    width: 100%;
    max-width: 1200px; 
    padding: 0;
}
a:not([href]):not([class]), a:not([href]):not([class]):hover{
    color : none;
}
/* ############## Header ############### */
header{
    z-index: 200;
    background-color: #fff;
    box-shadow: 0 2px 2px rgb(0 0 0 / 10%);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 50;
    transition: padding linear 300ms;
}
header .logo img{
    width: 190px;
    transition: all ease-in 300ms;
}
.change_header .logo img{
    width: 180px;
}
.change_header{
    opacity: 1;
    top: 0;
    transition: all ease-in 150ms;
}
.change_header.hidden{
    opacity: 0;
    top: -180px;
}
.change_header .logo-search
{
    padding: 20px 0;
}
.original_header .logo img{
    width: 230px;
}

/* Sitemap */
.sitemap-outer{
    position: absolute;
    top: 52px;
    box-sizing: border-box;
    position: absolute;
    width: 422px;
    border: solid 1px #dddddd;
    background: #f7f7f7;

    max-height: 450px;
    overflow: auto;
}
.sitemap-outer .CategoryList{
    table-layout: auto;
    position: relative;
    max-height: 100000px;
}
.sitemap-outer .categorySub{
    position: absolute;
    top: 0;
    left: 220px;
    border: none;
    background: transparent;
    padding: 0;
}
.categoryDepth1 {
    padding: 10px 0px;
    display: block;
    box-sizing: border-box;
    width: 220px;
    background: #fff;
    padding-left: 40px;
    text-align: left;
    font-size: 13px;
    color: #000;
    cursor: pointer;
}
.categoryDepth1 a {
    font-weight: 700;
    display: inline-block;
    width: 100%;
    height: 100%;
}
.categoryDepth1:hover{
    background-color: var(--main-color);
    color: #fff;
}
.categoryDepth1.active-category{
    background-color: var(--main-color);
    color: #fff;
}

/* 초기에는 categorySub를 숨깁니다 */
.categoryDepth1 .categorySub {
  display: none;
  /* display: flex; */
  flex-direction: row;
}
.categoryDepth1 .categorySub a{
    display: block;
    padding: 10px 0px 10px 30px;
    color : #222;
    font-weight: 700;
}
/* categoryDepth1를 hover할 때 categorySub를 보여줍니다 */
.categoryDepth1:hover .categorySub {
  display: block;
}
.categoryDepth1.active-category .categorySub {
    display: block;
}

.categoryDepth1 a:hover{
    color: #fff !important;
}
.categoryDepth1:hover .categorySub a:hover {
    color : var(--main-color) !important;
}
.sitemap{
    display: flex;
}
.sitemap div{
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
}

.category-list{
    display: flex;
    flex-direction: column;
}
.category-list .c-tit{
    padding-bottom: 20px;
    color: #3B4302;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 17px;
       /* text-align: center; */
    padding: 7px;
    margin-bottom: 5px;
    display: block;
 
}
.category-list .c-tit:hover{
    color: #fff !important;
}
/* LNB */
.lnb-outer{
    background: #0B75B8;
    color: #949494;
    border-bottom: 1px #f0f0f0 solid;
}
.lnb{
    max-width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
}
.lnb-navi{}
.lnb-navi a{
    /* font-size: 12px; */
    color: #f0f0f0 !important;
    font-weight: 500;
    cursor: pointer;
}
.lnb-navi a:after{
    content: '|';
    /* font-size : 12px; */
    color :#bbb;
    margin-right: 5px;
    padding-left: 5px;
}
.lnb-navi a:last-child:after{
    display: none;
}

.lnb-navi a:hover{
    color : #004393;
}
.c-icon{
    font-size: 20px;
    margin-right: 5px;
}
/* Logo */
.logo{
    cursor: pointer;
}
.logo-outer{}
.logo-search{
    width: 1200px;
    margin : auto;
    /* text-align: center; */
    padding: 35px 0;    
    position: relative;

}
/* GNB */

.gnb-outer{
    border-top: 1px #f0f0f0 solid;
    background-color: #fff;
}
nav{
    max-width: 1200px;
    /* width: 1320px; */
    margin : auto;
    position: relative;
}
.gnb{
    margin: 0;
    align-items: center;
    display: flex;
    gap: 57px;
    height: 52px;
    line-height: 52px;
}

.gnb a{
    cursor: pointer;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    transition: all 0.3s;
}
.gnb div{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100px; */
}
.nav_category_area{
    width: 130px;
    font-size: 16px;
    font-weight: 700;
    color: #333;
    cursor: pointer;
}
.nav_category_area span{

}
.gnb a:hover{
    color : var(--main-color) !important;
}
.gnb li:first-child{
    width: 130px;
    color : var(--main-color);;
    border-left: 1px #f0f0f0 solid;
    border-right: 1px #f0f0f0 solid;
}
.gnb li:first-child a{
    font-weight: bold;
    line-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Side Menu */
/* 
.tabMenuBtn{
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -6px;
}
.tabMenuBtn input[id=trigger] {
	display : none;
}
.tabMenuBtn label[for=trigger] {
	width : 30px;
	height : 20px;
	display : block;
    position: relative;
    cursor: pointer;
}
.tabMenuBtn label[for=trigger] span {
	position : absolute;
	height : 2px;
	background-color : #000;
    width: 100%;
	left : 0;
	transition : 0.3s;
}
.tabMenuBtn label[for=trigger] span:nth-child(1) {
	top:0;
}
.tabMenuBtn label[for=trigger] span:nth-child(2) {
	top:50%;
}
.tabMenuBtn label[for=trigger] span:nth-child(3) {
	top:100%;
}
.tabMenuBtn input[id=trigger]:checked + label[for=trigger] span:nth-child(1) {
    top:50%;
    transform: rotate(45deg);
}
.tabMenuBtn input[id=trigger]:checked + label[for=trigger] span:nth-child(2) {
    opacity: 0;
}
.tabMenuBtn input[id=trigger]:checked + label[for=trigger] span:nth-child(3) {
    top:50%;
    transform: rotate(-45deg);
}
.tabMenuBtn input[id=trigger]:checked ~ #layout_side{
    left: 0px;
}
.tabMenuBtn input[id=trigger]:checked ~ #layout_side_background{
    display: block;
}  
*/
.layout_side {
    position: fixed;
    left: -300px;
    top: 0px;
    box-sizing: border-box;
    height: 100%;
    padding-bottom: 20px;
    overflow: auto;
    width: 290px;
    z-index: 2001;
    background-color: #fff;
    display: block;
    transition: 0.3s;
    box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
}
.layout_side.aside-open{
    left: 0px;
}
.layout_side_background{
    z-index: 990;
    background-color: #333;
    opacity: 0.6;
    filter: alpha(opacity=60);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: none;
    pointer-events: auto;
}
.layout_side_background.aside-open{
    display:block
}
.aside_userinformation {
    padding: 10px;
    text-align: left;
    border-bottom: 1px #ddd solid;
}
.aside_userinformation .userinfo{
    color: #333;
    font-size: 14px;
    font-weight: 500;
}
.aside_userinformation>ul{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.aside_userinformation>ul>li:nth-child(2) a:nth-child(1){
    margin-right: 5px;
}

.btn_side{   
    display: inline-block;
    border: 1px #333 solid;
    background: #fff;
    height: 28px;
    font-size: 12px;
    line-height: 26px;
    padding: 0 9px;
    box-sizing: border-box;
    text-align: center;
    color: #333;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 2px;
    transition: border-color 0.2s, background-color 0.2s;
    font-weight: 500;
}

.aside_main_menu {
    padding: 10px 5px;
}
.aside_main_menu>ul {
    font-size: 0;
    line-height: 0;
}
.aside_main_menu>ul>li {
    display: inline-block;
    width: 25%;
    vertical-align: top;
    font-size: 13px;
    line-height: 1.3;
    text-align: center;
}
.aside_main_menu>ul>li.am_home>a {
    background-image: url('/src/assets/img/home_icon.svg');
}
.aside_main_menu>ul>li.am_order>a {
    background-image: url('/src/assets/img/home_icon.svg');
}
.aside_main_menu>ul>li.am_my>a {
    background-image: url('/src/assets/img/home_icon.svg');
}
.aside_main_menu>ul>li.am_cs>a {
    background-image: url('/src/assets/img/headset_icon.svg');
}
.aside_main_menu>ul>li.am_cart>a {
    background-image: url('/src/assets/img/cart_icon.svg');
}
.aside_main_menu>ul>li.am_wish>a {
    background-image: url('/src/assets/img/heart_icon.svg');
}
.aside_main_menu>ul>li.am_coupon>a {
    background-image: url('/src/assets/img/home_icon.svg');
}
.aside_main_menu>ul>li.am_emony>a {
    background-image: url('/src/assets/img/home_icon.svg');
}
/* .aside_main_menu>ul>li>a {
    display: block;
    vertical-align: top;
    padding-top: 50px;
    padding-bottom: 5px;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50% 10px;
    background-size: 32px;
    font-size: #333;
    font-weight: 500;
} */
.aside_main_menu>ul>li>a {
    /* [hm] react-icons로 변경 : 23.11.23 */
    display: block;
    vertical-align: top;
    padding-bottom: 5px;
    position: relative;
    font-weight: 500;
}
.aside_main_menu>ul>li>.icon {
    font-size: 24px;
    color: #0b6daa;
    margin: 6px 0px;
}
.aside_navigation_wrap ul.tab {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-top: 1px #aaa solid;
    position: relative;
    left: -1px;
    z-index: 3;
}
.aside_navigation_wrap ul.tab>li.current {
    background: none;
    border-bottom: none;
    color: #333;
    font-weight: 500;
}
.aside_navigation_wrap ul.tab>li.current span{
    font-weight: 700;
}
.aside_navigation_wrap ul.tab>li span{
    font-weight: 500;
}
.aside_navigation_wrap ul.tab>li{
    display: table-cell;
    line-height: 40px;
    text-align: center;
    background-color: #f6f6f6;
    border-left: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

/* Top Search Area*/
.search{
    /* position: absolute;
    top: 50%;
    right: 0;
    margin-top: -10px; */
    margin: 0 auto;
}
.search-area{
    box-sizing: border-box;
    /* width: 80%; */
    height: 40px;
    padding: 0 8px;
    margin: 0 auto;
    position: relative;
    border: 2px #0B6DAA solid;
    background: #fff;
    border-radius: 25px;
}
.search_box{
    background: transparent;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    vertical-align: middle;
    padding-left: 8px;
}
.search-area input{
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
    vertical-align: middle;
    background: transparent;
}
.search-area button{
    position: absolute;
    top: 6px;
    right: 6px;
    background: none;
    border: none;
    font-size: 20px;
}
/* 메인페이지 슬라이드배너 */
.carousel{
    max-width: 1200px !important;
    margin: 0 auto !important;
}
.carousel .simg {
  max-width: 100%;
  height: auto !important;
}

.simg{
    height: 600px;
    background-size: cover;
    background-position: center;  
}
.simg.banner-1{
    background-image: url('https://imagedelivery.net/YH9_GnBEWq52U8rOuaomwQ/2bdfe1d2-a339-497b-eb74-b6312692b500/w=1400');
}
.simg.banner-2{
    background-image: url('https://imagedelivery.net/YH9_GnBEWq52U8rOuaomwQ/206ca529-17c7-41e9-1b64-278425cc6b00/w=1400');
}
.slick-dots {
    bottom: 17px !important;
}
.slick-prev:before, .slick-next:before {
    /* color: #0B75B8 !important;
    font-size: 2rem !important; */
}
/* Location */
.location_wrap .location_cont {
    max-width: 1260px;
    margin-top: 25px;
}
.location_cont span {
    padding: 40px 0 0;
    color: #747474;
    font-size: 15px;
    font-weight: 300;
}
/* Login Page*/
.sub_content {
    padding: 60px 0 0;
}
.login_wrap{
    width: 459px;
    float: none;
    margin: 0 auto;
}
.member_tit{
    margin: 0 0 40px;
    color: #000;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    line-height: 1;
}
.login_tab ul{
    padding-left: 0;
    display: flex;
    justify-content: space-between;
}
.login_tab ul li{
    cursor: pointer;
    width: 49%;

}
.login_tab ul li a {
    display: block;
    height: 56px;
    color: #747474;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 56px;
    border: solid 1px #b6b7b7;
    border-radius: 10px;
    background: #fff;
    box-sizing: border-box;
}

.login_tab ul li.on a {
    color: #fff;
    border-color: var(--main-color);
    background: var(--main-color);
}

.login_tab ul li.on a:hover{
    color : #fff;
}
.login_input_sec input[type=text],
.login_input_sec input[type=password]
{
    border: none;
    width: 100%;
    height: 58px;
    margin: 10px 0;
    padding: 0 0 0 30px;
    color: #000;
    /* color: #747474; */
    font-size: 15px;
    font-weight: 300;
    border-color: #f4f4f4;
    background: #f4f4f4;
    border-radius: 10px;
}
.login_input_sec input[type=email]::placeholder,
.login_input_sec input[type=password]::placeholder{
    opacity: 1;
    transition: 0.3s;
}
.login_input_sec input[type=email]:focus::placeholder,
.login_input_sec input[type=password]:focus::placeholder{
    opacity: 0;
}
.login-button button {
    display: block;
    width: 100%;
    height: 58px;
    margin: 10px 0;
    color: #151515;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    line-height: 58px;
    border: solid 1px #b6b7b7;
    border-radius: 10px;
    background: #fff;
    box-sizing: border-box;
}
.login_btn{
    color: #fff;
    border-color: var(--main-color);;
    background: var(--main-color);;
    width: 100%;
}
.login-button .login_btn{
    color: #fff;
    border-color: var(--main-color);;
    background: var(--main-color);;
}
.login-option{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.login-option a,
.login-option label{
    color: #151515;
    font-size: 15px;
    font-weight: 400;
}
.member_sns_login{
    margin-top: 42px;
}
.member_sns_login > p {
    position: relative;
    color: #151515;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    line-height: 1;
}
.member_sns_login > *:first-child {
    margin-top: 0;
}
.member_sns_login > p::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    width: 170px;
    height: 1px;
    background: #e2e2e2;
    z-index: -10;
}
.member_sns_login > p::after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 170px;
    height: 1px;
    background: #e2e2e2;
    z-index: -10;

}
.member_sns_login a.btn_kakao_login {
    display: block;
    height: 58px;
    margin: 40px 0 0;
    color: #3c1e1e;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    line-height: 58px;
    border: solid 1px #ffeb00;
    border-radius: 10px;
    background: #ffeb00;
}
.member_sns_login a.btn_naver_login {
    display: block;
    height: 58px;
    margin: 20px 0 0;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    line-height: 58px;
    border: solid 1px #00c73c;
    border-radius: 10px;
    background: #00c73c;
}
.member_sns_login a.btn_naver_login img,
.member_sns_login a.btn_kakao_login img {
    vertical-align: middle;
    margin: 0 8px 0 0;
}
.saveId{
    margin-bottom: 15px;
}
.saveId input[type=checkbox]{
    display: none;
}
.saveId label em{
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    background: url('/src/assets/img/ch2.svg') no-repeat;
    background-position: left center;
}

.saveId input[type=checkbox]:checked + label em{
    background: url('/src/assets/img/ch2.svg') no-repeat;
}

.saveId label{
    margin-left: 10px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
}


/* 인기상품 UI Items */
.MItemheading{
    margin-top: 80px;
}
.conBox{
    padding: 60px 0;
}
.items-inner{
    /* width: 1350px; */
    max-width: 1200px;
    margin: auto;
}
.items-inner .heading{
    font-size: 30px;
    color: #000;
    letter-spacing: -2px;
    font-weight: 500;
    padding-bottom:10px;
}
.pr_title2 {
    font-size: 18px;
    letter-spacing: -1px;
    color: #777;
    padding-bottom:24px;
    font-weight: 500;
}
.conBox .items {
    display: flex;
    justify-content: space-between;
}

.conBox .item{
    /* border: 1px solid #000; */
    flex: 1;
    height: 400px;
    margin: 8px;
}
.thumb{
    min-width: 160px;
    min-height: 160px;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
}
.thumb a{

}
.thumb a:hover img {
    transform: scale(1.1);
}
.thumb img:hover{
    transform: scale(1.1);
}

.thumb img{
    position: absolute;
	top: 0;
	left: 0;
    transition: all 0.2s linear;
    width: 100%; 
    height: 100%;
    object-fit: cover;
}

.prd-info{
    /* margin-top: 10px; */
}
.prd-info span{
    display: block;
    color: #333;
    /* text-align: center; */
    font-weight: 500;
    font-size: 15px;
    line-height: 1.4;
}


/* join-contents.js 회원가입 페이지 */
.join_wrap{
    width: 600px;
    max-width: 600px;
    float: none;
    margin: 0 auto;
}
.titleArea {
    padding: 30px 0 0;
    border-bottom: none;
    border-bottom: 1px solid #303030;
    margin-bottom: 0;
}
.titleArea h2 {
    display: block;
    text-align: center;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 30px;
}
.titleArea p {
    width: 100%;
    border: none;
    clear: both;
    margin: 30px 0 20px;
    padding: 0;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 19px;
}
.titleArea p span {
    float: right;
    color: #595959;
    margin-top: 5px;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
}
.titleArea p span i {
    vertical-align: middle;
    display: inline-block;
    width: 5px;
    height: 5px;
    background:var(--main-color);
    margin-right: 7px;
    border-radius: 5px;
}

.join-table{
    padding-top: 30px;
}
.join-table table{
    width: 100%;
}
.join-table tbody th {
    vertical-align: middle;
    position: relative;
    border: none;
    background: transparent;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    padding: 0 0 40px;
    font-size: 17px;
}
.join-table tbody th i {
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #004393;;
    margin-left: 7px;
    border-radius: 5px;
    margin-top: 5px;
    vertical-align: top;
}
.join-table td{
    vertical-align: middle;
    border: none;
    background: transparent;
    padding: 0 0 40px;
}
.join-table td input[type=password], 
.join-table td input[type=text] {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
    height: 58px;
    box-sizing: border-box;
    line-height: 58px;
    border: 1px solid #E2E2E2;
    color: #303030;
    font-size: 16px;
    font-weight: 400;
    font-family: 'NanumSquare' ,sans-serif;
    transition: 0.2s;
}

.join-table td input[type=password]:focus,
.join-table td input[type=text]:focus{
    border: 2px solid #004393;
}
.join-table .error {
    display: none;
    margin-top: 12px;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
    color: #595959;
}

.join_wrap > h3 {
    border-bottom: 1px solid #303030;
    position: relative;
    padding: 50px 0 20px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 20px;
}
.join_wrap #birth_year,
.join_wrap #birth_month,
.join_wrap #birth_day{
    width: 80px;
    margin: 0 4px 0 0;
}
.plus .birth td span {
    padding: 0 10px;
    vertical-align: middle;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 17px;
}
.join-agreeArea input[type=checkbox]{
    display: none;
}
.join-agreeArea label em{
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    background: url('/src/assets/img/ch2.svg') no-repeat;
    background-position: left center;
}

.join-agreeArea input[type=checkbox]:checked + label em{
    background: url('/src/assets/img/ch2.svg') no-repeat;
}
.join-agreeArea{
    margin-bottom: 15px;
}
.join-agreeArea label{
    margin-left: 10px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
}

.join-agreeArea .morebtn {
    display: inline-block;
    cursor: pointer;
    float: right;
    color: #828282;
    letter-spacing: -0.03em;
    text-decoration-line: underline;
    font-weight: 400;
    font-size: 15px;
}
.base-button {
    padding: 25px 0 0 0;
}
.base-button button,
.base-button a {
    display: inline-block;
    width: 100% !important;
    padding: 0 !important;
    height: 58px;
    line-height: 55px;
    background: var(--main-color) !important;
    border-radius: 10px;
    text-align: center;
    color: #FFFFFF !important;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 20px;
    border: none;
}

/* product/Detail.js 상품 상세 페이지 */

.product-headcategory{
    margin: 0 auto 0;
    padding: 30px 0;
}
.product-headcategory li{
    list-style: none;
    display: inline;
}
.product-headcategory li a{
    padding: 0 10px;
    color: #828282;
    letter-spacing: -0.02em;
    font-size: 15px;
}
.product-headcategory li:nth-child(1) a{
    padding-left: 0;
}
.product-headcategory li::after{
    content: "";
    display: inline-block;
    height: 10px;
    width: 10px;    
    background: url('/src/assets/img/greyarrowdown.svg') no-repeat;
}
.product-headcategory li:last-child::after{
    display: none;
}
.product-image{
 
}
.product-image img{
    width: 510px;
    height: 510px;
    
    max-width: 510px;
    max-height: 510px;
    object-fit: cover;

    max-width: 100%;
    /* vertical-align: middle; */
    border: none;
    outline: none;
    /* border-radius: 5px; */
}
.product-addimage.listImg{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -5px;
}

.product-addimage li {
    flex: 0 0 calc(20% - 10px); /* 다섯 개의 열로 나누고 간격을 제외한 너비 설정 */
    margin: 5px;
    list-style: none;
}
  
.product-addimage li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 12px;
    border: solid 1px #111;
}

.infoArea{
    position: relative;
}
.infoArea .title{
    margin-top: 0;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.infoArea .title .sl{
    display: inherit;
    position: absolute;
    top: 9px;
    right: -11px;
}
.infoArea .title .sl li{
    margin-right: 5px;
}
    /* .infoArea .title .sl li:last-child::before {
        position: relative;
        left: 0px;
        top: 3px;
        content: '';
        display: inline-block;
        width: 1px;
        height: 25px;
        background: #E2E2E2;
    } */
.infoArea .title h3 span{
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 25px;
    line-height: 40px;
}  

.product-detail-t{

}
.product-detail-t table{
    margin-top: 15px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
}

.product-detail-t table th {
    width: 25%;
    padding: 0px 0px 10px;
    vertical-align: top;
    height: 30px;
    line-height: 30px;
}
.product-detail-t table td {
    padding: 0px 0px 10px;
    vertical-align: top;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    color: rgb(48, 48, 48);
    font-weight: 400;
}
.product-detail-t table td span{
    padding: 0px 0px 10px;
    vertical-align: top;
    height: 30px;
    line-height: 30px;
    font-size: 18px;
    color: rgb(48, 48, 48);
    font-weight: 400;
}
.product-detail-t table tr:nth-child(1) th{
    padding-top: 30px;
}
.product-detail-t table tr:nth-child(1) td{
    padding-top: 30px;
}
.product-detail-t table tr:last-child th{
    padding-bottom: 30px;
}
.product-detail-t table tr:last-child td{
    padding-bottom: 30px;
}
.product-detail-t table th span {
    letter-spacing: -0.02em;
    color: rgb(89, 89, 89) !important;
    font-weight: 400 !important;
    font-size: 17px !important;
}

.pd-select.opt-this-goods{
    width: 100%;
    padding-left: 27px;
    font-size: 16px;
    color: #000000;
    font-weight: 400;
    line-height: 47px;
    height: 47px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    margin-top: 25px;
    margin-bottom: 0.5rem;
}
.opt-selects-this{
    margin-top: 2rem;
}
.opt-selects-this .opt-sel {
    padding: 15px 22px 15px 27px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 5px;
    margin-bottom: 10px;
}
.opt-selects-this .opt-sel-name{
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 15px;
    color: #626161;
    line-height: 25px;
    vertical-align: top;
    float: left;
    box-sizing: border-box;
    width: 100%;
    display: inline-block;
}

.opt-selects-this .opt-sel-qty input{
    background: transparent;
    height: 25px !important;
    line-height: 25px !important;
    border: none;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #303030;
}
.opt-sel-qty{
    display: flex;
    align-items: center;
    justify-content: center;
}
.opt-sel-qty .down.QuantityDown{
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url('/src/assets/img/popminus.svg');
}

.opt-sel-qty .up.QuantityUp{
    width: 25px;
    height: 25px;
    display: inline-block;
    background: url('/src/assets/img/popplus.svg');
}
.opt-sm-wrap .opt-sel-del{
    display: inline-block;

    text-indent: -9999px;
    background: url('/src/assets/img/btn_del.png') center center no-repeat;
    background-color: #fff;
    border: 1px solid #ddd;
    width: 13px;
    height: 13px;
}
.opt-sm-wrap{
    display: flex;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.opt-sel-price{
    font-size: 20px !important;
    color: #333333 !important;
    font-weight: bold;
}

.btnWrap{
    display: flex;
    justify-content: space-between;
}
.btnWrap .btn_resp{
    width: 49%;
    transition: 0.1s;
}
.btnWrap .btn_resp:hover{
    border: 1px solid #434343;;
}

.btn_resp {
    border: 1px #ccc solid;
    background: #fff;
    height: 60px;
    font-size: 19px;
    font-weight: 400;
    line-height: 26px;
    padding: 0 9px;
    box-sizing: border-box;
    text-align: center;
    color: #333;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 2px;
    transition: border-color 0.2s, background-color 0.2s;
}
.btn_resp#buy{
    border-color: #434343;
    background-color: #434343;
    color: #fff;
}
.articleBody{
    margin: 50px 0px;
}
.nav-link{
    font-weight: 400;
    padding: 20px 0;
    font-size: 15px;
    color: #333333;
}


/* Product List page */
.page-tit{
    margin-bottom: 40px;
}
.page-tit h2{
    color: #222;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.4;  
    /* text-align: center; */
}
.menuCategory.mainCate{
    display: flex;
    /* gap:10px; */
    margin-bottom: 60px;
}
.menuCategory li:nth-child(1){
    border-left:  1px solid #e2e2e2;

}
.menuCategory li {
    width: 20%;
    margin-bottom: 20px;
    transition: all 0.3s;
    padding: 15px 20px;
    box-sizing: border-box;
    border: 1px solid #e2e2e2;
    border-left: 0;
}
.menuCategory li:hover{
    background: var(--main-color);
}
.menuCategory li:hover a{
    color: #fff;
}

.menuCategory li.on {
    background: var(--main-color);
}
.menuCategory li.on a{
    color: #fff;
}
.menuCategory > li > a
{
    background: none;
    color: #595959;
    padding: 0;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.item_gallery_type{
    cursor: pointer;
    margin-bottom: 40px;
}
.product-list-wrap{}
.product-list-wrap .prdCount{
    font-size: 14px;
    color: #303030;
    line-height: 1.4;
    font-weight: 500;
}
.pick_list_num{
    float: left;
    padding: 0;
    color: #151515;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -1px;
}
.product_ListMenu{
    height: 40px;
    margin: 14px 0 30px;
    /* padding: 9px 0 10px; */
    /* border-top: 1px solid #717171; */
    border-bottom: 1px solid #dadada;
}
.product_ListMenu .product-orderby{
    float: right;
}
.product_ListMenu .product-orderby li{
    float: left;
    color: #747474;
    height: 40px;
    font-size: 14px;
    line-height: 21px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 500;
}
.product_ListMenu .product-orderby li:hover{
    color : var(--main-color);
}

.product_ListMenu .product-orderby li a{
    display: block;
    padding: 0 0 0 18px;
    font-size: 13px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
}
.product_ListMenu .product-orderby li a:hover{
    color : var(--main-color);
}
.goods_no_data{
    min-height: 137px;
    box-sizing: border-box;
    text-align: center;
}
.goods_no_data strong {
    color: #151515;
    font-size: 16px;
    font-weight: bold;
    line-height: 137px;
}

.prdList{
    margin-bottom: 60px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 40px;
}

.prdList .thumbnail{
    margin-bottom: 10px;
}
.prdList .thumbnail .prdImg{
    overflow: hidden;

}
.prdList .thumbnail .prdImg img{
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: transform 0.5s;
    object-fit: cover;
}
.prdList .thumbnail .prdImg img:hover{
    transform: scale(1.1);
}

.prdList .description{

}
.prdList .prd-info .prd-name{
    font-size: 17px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.prd-info .prd-price{
    /* margin-top: 10px; */
}
.prd-price .prd-price-discount{
    font-size: 21px;
    color: #000;
    font-weight: bold;
    letter-spacing: -1px;
    margin-right: 1px;
    display: inline-block;
}
.paging_navigation{
    text-align: center;
    margin-bottom: 100px;
}
.paging_navigation a{
    display: inline-block;
    box-sizing: border-box;
    padding: 0 2px;
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 12px;
    font-weight: 500;
    color: #666;
    text-align: center;
    vertical-align: middle;
    border: 1px #ddd solid;
    margin-left: -1px;
    text-decoration: none;
    cursor: pointer;
}

.paging_navigation a.on {
    background-color: #fff;
    font-weight: 500;
    color: #333;
    border-color: #333;
    position: relative;
    cursor: default;
}
.paging_navigation a:first-child, .paging_navigation input:first-child {
    margin-left: 0;
}

/* Cart.js 장바구니*/

.tbl_wrap table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid #d3d3d3;

}
.tbl_wrap thead th input {
    vertical-align: top;
}
.tbl_wrap thead th {
    padding: 18px 0;
    border-top: 2px solid #000;
    border-bottom: 1px solid #d3d3d3;
    background: #fff;
    color: #383838;
    font-size: 1em;
    text-align: center;
    letter-spacing: -0.1em;
    font-weight: bold;
}
.td_prd{
    display: flex;
    gap: 10px;
}
.od_opt li{
    /* padding-left: 10px; */
    text-align: left;
    color: #777;
    margin: 3px 0;
    line-height: 1.3em;
    font-weight: 400;
}
.prd_name{
    cursor: pointer;
}
.tbl_wrap td {
    padding: 15px 5px;
    border-top: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
    line-height: 1.5em;
    word-break: break-all;
    font-weight: 400;
}
.total_prc {
    color: #ff006c;
    font-weight: bold;
    font-size: 1.167em;
}
.tbl_wrap td:nth-child(2),
.tbl_wrap td:first-child {
    border-left: 0;
    text-align: center;
}
.btn_cart_del {
    margin: 10px 0;
}
.btn_cart_del button {
    font-weight: 500;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #e2e2e2;
    border-bottom: 1px solid #c4c4c4;
    background: #fff;
    color: #0c0c0c;
    margin-right: 5px;
}
#od_bsk_tot {
    background: #f4f4f4;
    padding: 25px 0;
    margin: 20px 0;
    zoom: 1;
}
#od_bsk_tot ul{
    display: flex;
    justify-content: center;
    align-items: center;
}
#od_bsk_tot li:first-child {
    border: 0;
}
#od_bsk_tot li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 33.333%;
    border-left: 1px solid #e6e2e2;
    text-align: center;
    padding: 10px 0;
    font-size: 1.167em;
}
#od_bsk_tot li span{
    font-size: 1.1rem;
    font-weight: 600;
    vertical-align: -4px;
}
.cart_count{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cart_count button {
    line-height: 1em;
    float: left;
    width: 30px;
    height: 30px;
    border: 1px solid #cdcdcd;
    background: #fff;
    color: #666;
    font-size: 0.92em;
}
.cart_count button:hover {
    color: #000;
}
.cart_count .num_input {
    width: 40px;
    float: left;
    border: 0;
    height: 30px;
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    text-align: center;
}
.order-buttons{
    margin-top: 30px;
    text-align: center;
}
.goPayment, 
.goShopping {
    font-size: 19px;
    font-weight: 700;
    display: inline-block;
    position: relative;
    width: 170px;
    line-height: 18px;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    padding: 22px 0 19px;
    text-align: center;
}
.goShopping {
    border: 1px solid rgb(160, 160, 160);
    color: rgb(48, 48, 51);
    background-color: rgb(255, 255, 255);
    margin-right: 5px;
}
.goPayment {
    background: var(--main-color);;
    color: #fff;
}

/* Footer */
#footer {
    margin-top: 60px;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
    color: #767676;
    border-top: 1px #eee solid;
}
#footer .inner{
    width: 1200px;
    max-width: 1200px;
    margin: 0 auto;
}

#footer .black
{
    background-color: #000;
}
#footer .black .inner ul{
    padding: 20px 0;
}
#footer .black .inner ul li{
    display: inline-block;
    margin-right: 20px    
}
#footer .black .inner ul li a {
    display: block;
    color: #FFFFFF;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
}
#footer .bottom {
    background: #F7F7F7;
    padding: 30px 0px 70px;
    position: relative;
}
.layout-footer .menu3 li:nth-child(1)
{
    padding-left: 0;
}
.layout-footer .menu3 li:nth-child(7)
{
    padding-left: 0;

}
.layout-footer .menu3 li
{
    float: left;
    padding: 1px 0 1px 30px;
}

.layout-footer .menu3 li span{
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 14px; 
}

.mt50{
    margin-top: 50px;
}
#footer .center {
    width: 1200px;
    font-size: 0;
    margin: 40px auto 40px;
    display: flex;
    justify-content: space-between;
}
#footer .center .f_cs {

}
.foot_menu_d1{
    width: 33.3333%;
    max-width: 440px;
}

.foot_menu_d1 .title {
    margin-bottom: 15px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;    
}
.foot_menu_d1 h3
{
    margin-bottom: 20px;
    font-size: 29px;
    line-height: 1.2;
    font-weight: 600;
    color: #333;
    letter-spacing: -1px;
}
.foot_menu_d1 ul li {
    margin-bottom: 8px;
    line-height: 1;
    color: #595959;
    font-weight: 400;
    font-size: 14px;
    transform: skew(-0.03deg);
}
/* My page */
.mypage_wrap {
    /* border-top: 1px solid #cccccc; */
    /* border-bottom: 1px solid #ccc; */
    margin-bottom: 50px;
    font-size: 14px;
    padding: 0;
    display: flex;
}
.mypage_wrap .mypage_side {
    /* float: left;
    width: 30%; */
    /* width: 220px; */
    box-sizing: border-box;
    max-width: 260px;
}
.mypage_wrap .mypage_side .title {
    height: 110px;
    background: #f2f2f2;
    border-bottom: 1px solid #ccc;
    text-align: center;
    color: #333333;
    font-size: 23px;
    font-weight: bold;
    vertical-align: middle;
    line-height: 114px;
}
.mypage_wrap .mypage_side .sidemenu {
    /* padding: 20px; */
}
.mypage_wrap .mypage_area_sub {
    /* float: left;
    width: 70%; */
    /* border-left: 1px solid #cccccc; */
    min-height: 800px;
    box-sizing: border-box;
    /* padding: 30px; */
}
.mypage_wrap .mypage_side .sidemenu ul li {
    border-bottom: 1px solid #e5e5e5;
    /* padding: 18px 0; */
    padding: 20px;

    line-height: 180%;
}
.mypage_wrap .mypage_side .sidemenu ul li h3 {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
}
.mypage_wrap .mypage_side .sidemenu ul li a {
    margin-bottom: 7px;
    display: block;
    font-weight: 400;
    cursor: pointer;
}
.mypage_wrap .mypage_side .sidemenu ul li:last-child {
    border-bottom: none;
}

/* Customer page */
.customer_wrap{
    margin-top: 50px;
}
#contents {
    width: 100% !important;
    float: none;
    font-size: 0;
}

.sidebox {
    width: 211px;
    display: inline-block;
    vertical-align: top;
}

.sidebox h3 {
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 40px;
}

.sidebox .side li{
    cursor: pointer;
}
.sidebox .side li a {
    display: block;
    margin-bottom: 20px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 18px;
}
.sidebox .side li.on a {
    color: var(--main-color);
    font-weight: 700;
}

.sidebox .btbox {
    margin-top: 43px;
    background: #F7F7F7;
    border-radius: 15px;
    padding: 25px;
    box-sizing: border-box;
}
.sidebox .btbox .hi {
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 15px;
    line-height: 23px;
}
.sidebox .btbox .call {
    white-space: nowrap;
    color: var(--main-color);
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    margin: 10px 0 20px;
    letter-spacing: -2.9px;
}
.sidebox .btbox ul {
    padding-top: 20px;
    border-top: 1px solid #D9D9D9;
}

.sidebox .btbox ul li {
    color: #303030;
    font-weight: 400;
    font-size: 14px;
}

.sidebox .btbox .last {
    color: #595959;
    font-weight: 400;
    font-size: 14px;
    margin-top: 14px;
}
.customer_right {
    display: inline-block;
    width: calc(100% - 211px);
    font-size: 11px;
    padding-left: 60px;
    box-sizing: border-box;
}
.ctitleArea{
    margin-bottom: 30px;
}
.customer_right .ctitleArea h2 {
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 27px;
}
.customer_search_box{
    text-align: right;
    margin-bottom: 21px;
}

.customer_search_box input{
    outline: none;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
    width: 230px;
    height: 34px;
    box-sizing: border-box;
    border: 1px #ccc solid;
    /* border: 1px solid var(--main-color); */
    padding: 0 25px;
}
.customer_search_box input:focus{
    border: 1px var(--main-color) solid;
}

.customer_search_box .sBtn {
    padding: 0;
    width: 60px !important;
    line-height: 34px;
    height: 34px;
    margin-left: 10px;
    /* background: var(--main-color) !important; */
    /* color: #FFFFFF; */
    border: 1px #ccc solid;
    color: #333;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 15px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
}
.customer_search_box .sBtn:hover{
    border: 1px var(--main-color) solid;
    color : var(--main-color);
}
.xans-board-listpackage .ec-base-table {
    clear: both;
}

.ec-base-table.typeList table {
    margin: 0 0 10px;
    border-top: 1px solid #303030;
    border-right: none;
    border-left: none;
}
.ec-base-table tbody{
    margin-top: 20px;
    border-top: 4px solid #000;
}
.ec-base-table thead th {
    background: transparent;
    padding: 15px 0;
    line-height: 1;
    border: none;
    color: #303030;
    border-bottom: 1px solid #E2E2E2;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
}
.ec-base-table thead tr {
    text-align: center;
}
.ec-base-table.typeList.gBorder tbody td:nth-child(1) {
    text-align: center;
    color: #828282;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
}
.ec-base-table.typeList.gBorder tbody td:nth-child(2) {
    padding-left: 0 !important;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
    text-align: left;
}
.ec-base-table.typeList.gBorder tbody td {
    position: relative;
    padding: 20px 0;
    border: none;
    border-bottom: 1px solid #E2E2E2;
    line-height: 1;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
}
.ec-base-table.typeList.gBorder tbody td:nth-child(2) a{
    font-weight: 400;
}
.boardSort{
    margin-bottom:50px;
}
.boardSort ul {
    display: flex;
}
.boardSort ul li {
    display: inline-block;
    margin-right: 10px;
    text-align: center;
    padding: 12px 20px;
    background: #F7F7F7;
    border-radius: 50px;
}
.boardSort ul li.on {
    background: var(--main-color);
}
.boardSort ul li a {
    cursor: pointer;
    display: block;
    color: #828282;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 13px;
}
.boardSort ul li.on a {
    font-weight: 800;
    color: #FFFFFF;
}
.boardSort ul li.on a:hover{
    color: #FFFFFF;
}

.topnotice{

}
.noticeic {
    display: inline-block;
    width: 51px;
    height: 29px;
    line-height: 28px !important;
    border: 1px solid var(--main-color);
    border-radius: 50px;
    text-align: center;
    color: var(--main-color) !important;
    letter-spacing: -0.03em;
    font-weight: 600;
    font-size: 15px;
}
/* 페이지내비게이션 */
.c-paginate {
    margin: 30px 0;
    text-align: center;
    font-size: 0;
    line-height: 0;
}
.c-paginate ol li{
    display: inline-block;
    margin: 0 0 0 -1px;
    border: none;
    margin: 0;
    vertical-align: middle;
    margin-right: 10px;
}

.c-paginate ol li a {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 -1px;
    border: none;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #CACACA;
    border: none;
    padding: 0;
    font-weight: bold;
    font-size: 15px;
}

.c-paginate ol li.this {
    border: 1px solid var(--main-color);
    border-radius: 2px;
    color: var(--main-color);
    font-weight: bold;
    font-size: 15px;
}
.c-paginate ol li.this a{
    color: var(--main-color);
}

.empty_table{
    padding: 50px !important;
    color: #777 !important;
}
.osutxt{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 13px;
}

/* Find page */
.find_wrap{
    text-align: center;
}
.find_wrap #contents{
    width: 420px !important;
    margin: 0 auto !important;
    float: none !important;
    padding-bottom: 100px;
}
.find_wrap #contents .check
{
    display: flex;
    align-items: center;
    margin: 30px 0px;
}

.find-titleArea{
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px !important;
    color: #303030;
}
.findId{
    margin: 7px 0 0;
}
.findId input[type=text], .findId input[type=password]{
    width: 100% !important;
    height: 58px !important;
    color: #303030;
    line-height: 58px;
    box-sizing: border-box;
    margin-bottom: 12px;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
    padding: 0 25px;
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    transition: 0.5s;
}
.findId input[type=text]:focus{
    border: 1px solid var(--main-color);
}

.find_wrap .check input[type=radio] {
    width: 19px;
    height: 19px;
}

.findId .ec-base-button ul {
    margin-top: 25px;
    text-align: center;
}
.ec-base-button ul {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 22px;
}

.ec-base-button ul li a {
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 18px;
}
/* .ec-base-button ul li:nth-child(2)::after {
    position: absolute;
    top: 5px;
    left: -20px;
    content: '';
    width: 1px;
    height: 18px;
    background: #E2E2E2;
}
*/
.findId strong {
    text-align: left;
    margin-bottom: 12px !important;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
}
input[type=radio] + label {
    vertical-align: middle;
    margin: 0 15px 0 8px !important;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 15px;
}

.ec-base-button button{
    display: block;
    width: 100%;
    height: 57px;
    line-height: 58px;
    text-align: center;
    font-family: 'NanumSquare';
    padding: 0;
    background: var(--main-color);
    border-radius: 10px;
    color: #FFFFFF !important;
    letter-spacing: -0.02em;
    font-weight: 600;
    font-size: 20px;
    margin-top: 20px;
    border: none;
}
.name_label{
    
}
.find-titleArea h2{
    color: #303030;
    padding: 70px 0 30px;
    letter-spacing: -0.02em;
    font-weight: 500;
    font-size: 33px;  
}
.find-titleArea p{
    color: #828282;
    letter-spacing: -0.02em;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 17px;
    line-height: 26px;
}
/* Faq */
.faq-content-wrap{
    margin-top: 20px;
    border-top: 1px solid #303030
}
.accordion{
    width: 100%;
    color : #000;
}

.accordionHeading{
    background: #fff;
    padding: 14px 0;
    border-bottom: 1px solid #D9D9D9;
}
.accordionHeading .container{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    /* justify-content: space-between; */
    padding: 20px 0px;
}

.accordionHeading p{
    cursor: pointer;
    text-align: left;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
    padding-left: 10px;
    box-sizing: border-box;
    color: #303030;
}

.accordionHeading span{
    cursor: pointer;
}
.accordionHeading .container .cate{
	transform: skew(0.03deg);
    display: inline-block;
    width: 17%;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
}
.accordionHeading .container .cate img{
    padding-right:5px;
}
.accordionContent.container img{
    margin-right: 10px;
}
.accordionContent {
    background: #F7F7F7;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
    height: 0;
    opacity: 0;
    display: flex;
    overflow: hidden;
    transition: all 600ms ease-in-out;
}
.accordionContent .container{
    padding: 20px;
}
.accordionContent .container p img{
    margin-right: 10px;
}
.accordionContent.show{
    height: 180px;
    opacity: 1;
}
.accordionContent p{
    font-size: 15px;
    line-height: 1.6;
}

#categorySideMenu{

}
#categorySideMenu li{
    border-bottom: 1px solid #eee;
    padding: 12px;
    text-align: left;

}
#categorySideMenu li a{
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3;
    padding-left: 20px;
}

/* Resister page */
.tit_wrap{

}
.tit_wrap h3 {
    margin-bottom: 50px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    text-align: left;
    font-size: 28px;
    font-weight: 600;
    color: #333;
}
.tab_wrap{
    border-bottom: 2px solid #555;
}
.tab_wrap > ul li {
    display: inline-block;
}
.tab_wrap > ul {
    margin: 0 auto -2px;
}
.tab_wrap a{
    display: inline-block;
    width: 200px;
    height: 42px;
    line-height: 35px;
    font-weight: bold;
    border: 2px solid #e0e0e0;
    border-bottom: 2px solid #555;
    background: #fafafa;
    text-align: center;
    font-size: 16px;
    color: #999;
    cursor: pointer;
}
.tab_wrap a.on{
    border: 2px solid #555;
    border-bottom: 2px solid #fff;
    background: #fff;
    font-weight: bold;
    color: #333;
    cursor: default;
}
.cont_tit{
    line-height: 1.6;
    letter-spacing: -1px;
    font-size: 17px;
    margin-bottom: 11px;
    margin-top: 30px;
}

.basic_wrap {
    margin: 0 auto;
}
.res_form_wrap {
    border-top: 1px solid #888;
}
.res_form_wrap .tbt_tit {
    width: 150px;
    font-size: 15px;
    color: #444;
    padding-left: 20px;
    font-weight: 600;
}
.res_form_wrap td {
    border-bottom: 1px solid #cdcdcd;
    height: 49px;
    font-size: 14px;
}
.res_form_wrap td input[type=text], .res_form_wrap td input[type=password] {
    width: 193px !important;
    height: 32px !important;
    border: 1px solid #ddd;
    padding: 0px;
    text-indent: 10px;
    font-size: 14px;
    font-family: 'Noto Sans KR', sans-serif;
    margin-bottom : 0;
}

.red_star{
    color : #f00;
}
.res_form_wrap .desc, .res_form_wrap .small {
    font-size: 14px;
    font-weight: 500;
    color: #888;
}
.res_form_wrap .desc {
    display: inline-block;
    padding-left: 10px;
}
.res_form_wrap .pdtb9 {
    padding: 9px 0px;
    font-weight: 500;
}
.basic_wrap input[type="checkbox"], .basic_wrap input[type="radio"] {
    width: 18px;
    height: 18px;
}
.res_form_wrap .select_wrap01 select {
    width: 100%;
    height: 28px;
    border: 0px;
    background: #fff;
    font-weight: 500;
}
.res_form_wrap .select_wrap01 select option{
    font-weight: 500;
}
.res_form_wrap .select_wrap01 {
    display: inline-block;
    width: 193px;
    height: 28px;
    border: 1px solid #ddd;
    margin-left: 10px;
}
.res_form_wrap .sub_text_line {
    font-size: 13px;
    border-bottom: 1px solid #cdcdcd;
    padding-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color: #696969;
    font-weight: 500;
}
.res_form_wrap .sub_text {
    padding-left: 10px;
    text-indent: -8px;
    font-size: 13px;
    color: #696969;
    font-weight: 600;
}
.res_form_wrap .addx{
    padding-top: 5px;
    display: block;
}
.res_form_wrap .inpt3 input{
    width: 64px !important;
}

.btn_wrap .btn_submit:hover {
    background: #0654a2;
}
.btn_wrap .btn_submit {
    display: inline-block !important;
    vertical-align: top;
    margin: 0 5px;
    width: 160px;
    height: 50px;
    line-height: 48px;
    border-radius: 2px;
    border: 0;
    background: #1d6cbc;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all ease 0.3s;
    font-weight: 600;
}
.btn_wrap {
    padding-top: 50px;
    text-align: center;
}

.btn_wrap .btn_cancel:hover {
    background: #343434;
}

.btn_wrap .btn_cancel {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    width: 160px;
    height: 50px;
    line-height: 48px;
    border-radius: 2px;
    border: 0;
    background: #545454;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    transition: all ease 0.3s;
    font-weight: 600;
}

.res_form_wrap .pdtb9 {
    padding: 9px 0px;
}
.side_close{
    display: block;
    position: fixed;
    left: 295px;
    margin-top: 20px;
    z-index: 2010;
    width: 0;
    height: 30px;
    text-indent: -9999px;
    transition: width 0.4s 0.8s;
    overflow: hidden;
}

/* Agreement page */
.agreement_wrap{
    margin-top: 50px;
}

.agreement_wrap .tit_wrap h3 {
    margin-bottom: -7px;
    border-bottom: 0;
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #333;
}
.agreement_wrap .tit_wrap p{
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}
.resp_login_wrap {
    max-width: 480px;
    margin: 30px auto 0;
    border: 1px solid #e0e0e0;
    padding: 40px 50px 50px;
    text-align: center;
}
.resp_login_wrap span{
    font-weight: 500;
    margin-bottom: 20px;
    display: inline-block;
}
.btn_resp.color2 {
    height: 53px;
    line-height: 50px;
    border-radius: 5px;
    border-color: #1d6cbc !important;
    background: #1d6cbc !important;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    width: 100%;
}
.list_dot_01{
    list-style:disc;
    text-align: left;
    letter-spacing: -0.3p
}

.list_dot_01 li{
    font-weight: 500;
    margin-bottom: 20px;
}


/* hm custom */
.fs-7{
    font-size: 0.833rem;
}
.fs-8{
    font-size: 0.667rem;
}
.fw-800{
    font-weight: 800 !important;
}
.line-height-15{
    line-height: 1.5;
}
/* header */
.header-icon{
    color: #0B6DAA;
    cursor: pointer;
}
.header-icon:hover{
    color: #000000;
}
.gnb li a{
    font-size: 14px;
}
.mobile-gnb{
    display: none;
}
/* product info */
.prd-info .prd-name-wrap{
    height: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.prd-info .prd-name{
    font-size: 14px;
    font-weight: bold;
    
}
.prd-info .prd-seller{
    font-size: 12px;
    font-weight: bold;
    color: #595959;
    letter-spacing: -0.5px;
}
.prd-info .prd-price{
    font-size: 16px;
    font-weight: bold;
    color: #0B6DAA;
}
.prd-info .prd-price-sub{
    font-size: 12px;
    font-weight: bold;
    color: #595959;
    text-decoration: line-through;
}
.product-info-badge{
    background-color: #eff8ff;
}

/* color */
.text-hw{
    color: #0B6DAA;
}
.hover-hw:hover{
    color: #0B6DAA;
}
.text-grey{
    color: #6c757d;
}
.text-dark-grey{
    color: #595959;
}
.bf-click{
    border: 1px solid var(--main-color) !important;
    color: var(--main-color) !important;
}
.mcb{
    letter-spacing: -0.02em;
    color: #595959;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
}
.bg-hw{
    background-color: #0B6DAA;
}
.bg-hw-light{
    background-color: #E9F6FF;
}
.bg-grey{
    background-color: #f7f7f7
}
.border-hw{
    border: 1px solid #0B6DAA;
}
.border-default{
    border: 1px solid var(--silver-gray);
}
.btn-hw{
    background-color: #0B75B8;
    color: #fff;
}
.btn-hw:hover{
    background-color: #0B6DAA;
    color: #fff;
}
.border-top-dark{
    border-top: 1px solid #000;
}
.border-bottom-dark{
    border-bottom: 1px solid #000;
}
/* custom tooltip */
.custom-tooltip{
    z-index: 99;
}
.custom-tooltip .tooltip-inner{
    background-color: #0B6DAA;
}
.custom-tooltip.tooltip-left .tooltip-arrow::before,
.custom-tooltip.tooltip-right .tooltip-arrow::before{
    border-left-color: #0B6DAA !important;
    border-right-color: #0B6DAA !important;
}
.custom-tooltip.tooltip-top .tooltip-arrow::before,
.custom-tooltip.tooltip-bottom .tooltip-arrow::before{
    border-top-color: #0B6DAA !important;
    border-bottom-color: #0B6DAA !important;
}
/* custom category breadcrumb */
.custom-breadcrumb ol{
    margin-bottom: 0; /* 하단 마진 제거 */
}
.custom-breadcrumb .active{
    color: var(--main-color);
    font-weight: bold;
    cursor: not-allowed;
}
.custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: '>'; /* 구분 기호를 '>'로 변경 */
    margin: 0 0.5rem; /* 기호 간격 조정 (원하는 간격으로 조정) */
}
/* main layout */
.section2{
    /* border-radius: 50% 50% 0 0; */
    /* background-image: url(https://cdn.crowdpic.net/detail-thumb/thumb_d_25D61D69342112D077CDB01D05D0292C.png); */
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-position: top right;
    background-position-y: -189px;
    background-color: #f9f9fa;
}


/* cursor */
.pointer{
    cursor: pointer;
}
.not-allowed{
    cursor: not-allowed;
}

/* 모든 스크롤바 숨김 */
/* 수평 스크롤바 숨김 */
  body::-webkit-scrollbar-horizontal {
  display: none;
}
/* 수직 스크롤바 숨김 */
  body::-webkit-scrollbar-vertical {
  display: none;
}
/* 디테일페이지 탭 초기화 */
.detail-tab .nav-tabs{
    --bs-nav-tabs-border-width: 0;
    --bs-nav-tabs-border-radius: 0;
    --bs-nav-tabs-link-active-color: #0B6DAA;
    font-weight: bold;
}
.detail-tab .nav-tabs .nav-item{
    /* border: 3px solid var(--main-color) !important;
    border-bottom: none !important;
     */
    border: solid 1px #dee2e6;
    background-color: #f7f7f7;
}
.detail-tab .nav-tabs .nav-link{
    margin-bottom: 0;
    /* font-weight: bold; */
    font-size: 17px;
    color: #000;
    letter-spacing: -0.1px;
    font-weight: 500;
}
/*  */
.agree-chk input[type=checkbox]{
    display: none;
}
/* 마이페이지 날짜선택창 커스텀 */
.date-picker-wrapper .react-datepicker__input-container input {
    border: 1px solid #ccc;
    width: 100px;
    padding-left: 8px;
    font-size: 12px;
    font-weight: 700;
    color: #595959;
}

/* input type number일때 + - 화살표 지우기 */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
input[type=number] {
    -moz-appearance: textfield;
}

/* box-shdow */
.shadow-box{
    box-shadow: 0px 3px 9px rgba(0,0,0,0.05);
}

/* hr dashed */
.dashed{
    border-top: 1px dashed;
}

.border-dashed{
    border-style: dashed;
    border-color: #e2e2e2;
}

.extraOptionbox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f6f7f8;
    padding : 10px;
}
.ModifiedExtra-tit{
    border-bottom: 1px solid #d9dde1;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -1px;
}

.board-wrap{
    border-top: 1px solid #353535;
    margin-top: 2rem;

}

.board-wrap .board-tit{
    padding: 20px;
    
}
.board-wrap .board-tit h2{
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 18px;
}
.board-sub{
    padding: 10px 20px;
    position: relative;

}
.board-sub span{
    color: #828282;
    font-weight: 400;
    font-size: 16px;
}
.board-sub .hit{
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: #828282;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 15px;
}
.board-sub span:nth-child(1) {
    margin-right: 2rem;
}
.board-detail{
    font-weight: 500;
    padding: 30px 0 30px;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    
}
.btnNormalFix{
    height: 40px;
    width: auto !important;
    padding: 0 44px !important;
    line-height: 38px;
    background: transparent !important;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    color: #828282;
    letter-spacing: -0.03em;
    font-weight: 700;
    text-align: center;
    font-size: 16px;
}
.board-btns{
    padding: 30px 0px;
}

.cursor-pointer{
    cursor: pointer;
}

.sideMu div{
    /* border-bottom: 1px solid #eee; */
    background-color: #fff;
    padding: 15px 0px !important;

}
.pagetit{
    padding: 12px 0 20px !important;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -.5px;
    /* border-bottom: 1px solid #222; */
}

.btnSubmitFix{
    width: 120px;
    padding: 0;
    height: 40px;
    line-height: 39px;
    text-align: center;
    background: var(--main-color);
    color: #FFFFFF;
    letter-spacing: -0.03em;
    font-weight: 700;
    font-size: 15px;
    /* border-radius: 10px; */
    border:none;
}
.btnSubmitFix2{
    width: 120px;
    padding: 0;
    height: 50px;
    line-height: 39px;
    text-align: center;
    background: var(--main-color);
    color: #FFFFFF;
    letter-spacing: -0.03em;
    font-weight: 700;
    font-size: 15px;
    /* border-radius: 10px; */
    border:none;
}
.btnNormal{
    border: 1px solid #E2E2E2;
    font-weight: 400;
    color: #595959;
    letter-spacing: -0.02em;
    font-size: 15px;
    width: 136px;
    background: #FFFFFF;
    border-radius: 10px;
}
.btnNormal2{
    border: 1px solid #E2E2E2;
    font-weight: 400;
    color: #595959;
    letter-spacing: -0.02em;
    font-size: 15px;
    background: #FFFFFF;
    width: 120px;
    padding: 0;
    height: 40px;
}
.btnSubmitFix.m
{
    background: #fff !important;
    border: 1px solid var(--main-color) !important;
    color: var(--main-color) !important;
}
.ec-base-table.typeWrite{

}

.ec-base-table.typeWrite tbody th {
    background: transparent;
    padding: 0 0 30px;
    border: none;
    color: #3B3B3B;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 16px;
    width: 120px;
    /* padding: 0; */
    padding-top:10px;
    border-right: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    vertical-align: top;
    display: table-cell;
    height: 62px;
    box-sizing: border-box;

}

.ec-base-table.typeWrite tbody td {
    padding-bottom: 20px;
    padding-left: 20px;
    padding-top : 10px;
    border-bottom: 1px solid #e4e4e4;
    /* 상품Q&A 에디터 최대 너비 제한 추가 */
    max-width: 480px;
}

.ec-base-table.typeWrite td input[type = "text"] {
    font-size: 16px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    width: 100% !important;
    height: 50px;
    padding-left: 10px;
}
.ec-base-table.typeWrite td input[type = "text"]::placeholder{
    color : #a0a0a0;
}
.ec-base-table tbody select {
    border: 1px solid #E2E2E2;
    width: 250px !important;
    height: 50px;
    font-family: 'NanumSquare';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: -0.02em;
    color: #000000;
    padding-left: 10px;
}
.ec-base-box.typeProduct {
    padding: 25px;
    margin: 30px 0;
    border: 1px solid #E2E2E2;
    border-radius: 20px;
    background: #FFFFFF;
}
.ec-base-box.typeProduct img{
    max-width: 120px;
    border: none;
    object-fit: cover;
}
.ec-base-box.typeProduct .sPrdName{
    margin-bottom: 12px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 18px; 
}
.ec-base-box.typeProduct .sPrdPrice {
    color: var(--main-color);
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 18px;   
}
/* .ck.ck-editor__main>.ck-editor__editable
{
    height: 500px;
} */

.ec-base-box.typeProduct .information{
    margin: 19px 0 0 0;
    padding: 13px 0 0 0;
    border-top: 1px solid #e8e8e8;
}





.popup-search-wrap{
    padding: 20px;
}

.popup-search-box{
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    padding: 25px;
    font-size: 0;
    display: flex;
    align-items: center;
}

.popup-search-box select{
    width: 149px;
    border: 1px solid var(--main-color);
    padding: 0 25px;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.02em;
    color: #303030;
    height: 50px;
}

.popup-search-box #keyword {
    margin: 0 15px 0 10px;
    font-family: 'NanumSquare' !important;
    width: calc(100% - 290px) !important;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
    line-height: 50px;
    height: 50px;
    padding: 0 20px;
    box-sizing: border-box;
    border: 1px solid var(--main-color);
}
.s-p-table{

}
.s-p-table table {
    margin: 0 0 10px;
    border-top: 1px solid #303030;
    border-right: none;
    border-left: none;
}
.s-p-table thead th {
    text-align: center;
    padding: 25px 0;
    color: #595959;
    border-color: #D9D9D9;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
}
.s-p-table td {
    padding: 11px 10px 10px;
    border-top: 1px solid #dfdfdf;
    color: #353535;
    vertical-align: middle;
    word-break: break-all;
    word-wrap: break-word;
}
.s-p-table td.left{
    padding-left: 25px;
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 15px;
}
.s-p-table td.cbtn{
    text-align: center;
}
.s-p-table td.left strong
{
    margin-top: 13px;
    color: var(--main-color);
    font-weight: bold;
    font-size: 15px;
}

.s-p-table td.thumbmain img {
    max-width: 120px;
    max-height : 120px;
    border: none;
}
.s-p-table td a{
    width: 68px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border: 1px solid #E2E2E2;
    color: #595959;
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 16px;
}
.ec-base-tab{
    /* display: flex;
    justify-content: space-between; */
    color: #303030;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    border-bottom: 2px solid #222;
    padding-bottom: 30px;
}
.ec-base-tab h3{
    font-weight: 700;
    font-size: 23px;
}
.ec-base-tab span{
    font-weight: 600;
    font-size: 25px;
    color: var(--main-color);
    margin-left: 10px;
}
.ec-base-tab .notice-p-txt{
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #8f8f8f;
    letter-spacing: -0.9px;
    margin-bottom: 30px;
}
.tit-box{
    display: flex;
    /* padding: 20px; */
    justify-content:space-between ;
    /* border-bottom: 1px solid #ebebeb; */
    cursor: pointer;
}
.ec-table{
    width: 100%;
}
.ec-table thead td{
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
}
.ec-table td{
    padding: 20px;
    text-align: center;
    font-weight: 400;
}

/* 상품상세페이지 Q&A 질문 표 가로로 늘어나는 오류 수정 */
.inquiry-table-box{
    overflow-y: hidden;
}
.inquiry-table-question-detail{
    max-width: 480px;
}

.detail-view{
    background: #F7F7F7;
    padding: 24px !important;
}
.review-view{
    background: #F7F7F7;
    padding: 14px 0 10px 20px !important;
    text-align: left;
}
.detail-view p {
    text-align: left;
    padding-bottom: 10px;
}
.view-answer{
    /* border-top: 1px solid #ebebeb; */
    align-items: center;
    text-align: left;
    padding: 20px !important;
    background: #F7F7F7;
    
}
.view-answer span{
    display: inline-block;
    margin-bottom: 10px;
    background: #80808094;
    padding: 3px 6px;
    font-size: 14px;
    color: white;
}
.q-b{
    box-sizing: border-box;
    padding-left: 8px;
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    color: #333;
    margin-right: 10px;
}
.answer-b{
    box-sizing: border-box;
    padding-left: 8px;
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    color: #b1b1b1;
    margin-right: 10px;
}
.fchecklist{
    list-style-type: none;
    text-align: left;
    margin-bottom: 10px;
}
.fchecklist input[type=checkbox]
{
    margin-right: 10px;
}
.fchecklist label {
    align-items: center;
    display: flex;
}
/* 별점 */
.star-rating {
    font-size: 24px;
}
  
.star {
cursor: pointer;
color: #a0a0a0;
}

.selected {
color: gold !important;
}

.text-left{
    text-align: left !important;
}
.text-right{
    text-align: right !important;
}

.review-inf{
    text-overflow: ellipsis;
    line-height: 17px;
    white-space: nowrap;
    display: block;
    margin: 9px 0px;
    font-size: 16px;
    color: #666;
    font-weight: 700;
}

.rmheader{
    text-align: left;
    position: relative;
    padding: 10px 0px;
    border-bottom: 2px solid rgb(48, 48, 51);
    box-shadow: none !important;
    margin-bottom: 1rem;
}

.rmheader h3{
    color: rgb(0, 0, 0);
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
}
.pd-rwr{
    display: flex;
    align-items: center;
}
.pd-rwr span{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.2px;
}

.pd-rwr-input{
    padding: 0px 50px 0px 0px;
    width: 100%;
    height: 225px !important;
    font-size: 14px;
    line-height: 22px;
    background: rgb(255, 255, 255);
    border: 0px;
    box-shadow: none;
    outline: none;
    resize: none;
}
.review-images-preview{
    display: flex;
    gap: 10px;
}
.review-images-preview button{
    width: 80px;
    height: 80px;
}

.review-image-preview{
    width: 80px;
    height: 80px;
    position: relative;
}
.review-image-preview img{
    width: 100%;
    position: absolute;
    z-index: 999;
    left: 0;
}
.review-image-preview .delete-button{
    cursor: pointer;
    position: absolute;
    z-index: 999;
    top: 0;
    right: 0;
    color : #fff;
    background-color: #120f0fc2;
    padding: 3px 8px;
    font-size: 15px;
}
.rWmodal-button-wrap{
    display: flex;
    justify-content: end;
}

.review-images-preview label{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.review-W-notice{
    font-size: 13px;
    word-break: keep-all;
    line-height: 17px;
    color: rgb(255, 72, 0);
    text-align: left;
    margin-top: 10px;
}
.ec-address{

}
.ec-address li{
    margin-bottom: 5px;   
}
.ec-address button{
    display: inline-block;
    box-sizing: border-box;
    padding: 7px 8px;
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    word-spacing: -0.5px;
    letter-spacing: 0;
    text-align: center;
    white-space: nowrap;
    color: #222;
    background-color: #f0f0f0;
}

.btn2{
    display: inline-block;
    width: 85px;
    line-height: 28px;
    border: 1px solid #004393;
    text-align: center;
    font-size: 14px;
    color: #004393;
    font-weight: 500;  
    border-radius: 1px;
    cursor: pointer;
}
.under-construction-info-box {
    color: #fff;
    background-color: rgba(0, 0, 0, .24);
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;
    display: flex;
    width: 45%;
}
.under-construction-info-box h3{
    font-weight: bold;
}
.under-construction-info-box p{
    text-align: center;
    margin: 2rem 0;
    letter-spacing: -0.7px;
    font-size: 17px;
}

.under-construction-info-box button{
    width: 140px;
    height: 50px;
    font-size: 1rem;
    text-align: center;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #fff;
    font-weight: 700;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(.19, 1, .22, 1);
    box-shadow: inset 0 0 #fff;
    color: #fff;
}

/* [hm] 토글 디자인 추가 : 2023.09.19 */
/* 슬라이더 기본 스타일 */
.toggle .toggle-slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 15px;
    transition: background-color 0.3s;
    cursor: pointer;
}

/* 슬라이더 스위치 */
.toggle-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s;
}

/* 토글 활성 상태 스타일 */
input:checked + .toggle-slider {
    background-color: #2196F3;
}

input:checked + .toggle-slider:before {
    transform: translateX(20px);
}


/* 클릭 불가능한(disabled) 스타일 */
.toggle-slider.disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.5;
}

/* 뱃지 */
.badge-area{
    display: flex;
    gap:8px;
    margin-top: 8px;
}
.prd-badge{
    display: inline-block;
    text-align: center;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 800;
}
.prd-badge.grey{
    background-color: #dedede;
    color: #505050;
}
.prd-badge.red{
    background-color: #ff6c6c;
    color: #ffffff;
}
.prd-badge.blue{
    background-color: #7b6cff;
    color: #ffffff;
}

/* 메인페이지 상품 좋아요 */
.thumb{
    position: relative;
}
.item-like{
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: grey;
    opacity: 0.6;
    z-index: 2;
}

/* 제철 수산물 카테고리 */
.seasonal-category{
    margin-bottom: 24px;
}
.seasonal-category-btn{
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    padding: 8px 24px;
    margin-right: 12px;
    border: solid 1px #6c757d;
    display: inline-block;
}
.seasonal-category-btn.active{
    background-color: #0d75b9;
    color: #fff;
    border: solid 1px #0d75b9;
    transition: all ease-in 500ms;
    cursor: pointer;
}
.seasonal-category-btn:hover{
    background-color: #000000;
    color: #fff;
    border: solid 1px #0d75b9;
    transition: all ease-in 500ms;
    cursor: pointer;
}
/* [hm] 메인페이지 웨이브 */
.wave-box{
    z-index: -12;
    margin-top: -580px;
}
.wave-box.pc-width{
    display: block;
}
.wave-box.mobile-width{
    display: none;
}
.wave-bottom{
    margin-top: -2px;
    margin-bottom: -180px;
    background-color: #f9f9fa;
}
/* [hm] 모바일 사이드바 카테고리 */
.side-category-block{
    padding-left: 24px;
}
.side-category-block.off{
    display: none;
}
.side-category-block.on{
    display: block;
}

/*  */
.contentA{
    resize: none;
    width: 100%;
    height: 300px;
}
.psmt{
    letter-spacing: -0.02em;
    font-weight: 400;
    font-size: 18px;
    margin: 1.4rem 0;
}
.order-detail-table {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    width: 100%;
}
.order-detail-table td{
    border-bottom: 1px solid #F0F0F0;
    padding: 12px 0;
}
.order-detail-table td:nth-child(1){
    font-size: 14px;
    font-weight: 400;
    padding: 12px 16px;
    border-bottom: 1px solid #eee;
    background-color: #fafafa;
    color: #111;
    overflow: hidden;
}
.order-detail-table td:nth-child(2){
    font-size: 15px;
    line-height: 1.75;
    word-break: keep-all;
    font-weight: 500;
    white-space: normal;
    word-break: break-all;
    padding: 12px 16px;
    color: #333;
}
.t-productReview-image img{
    max-width: 100px;
    max-height: 100px;
}


/* [hm] like button custom */
.heart-container {
    --heart-color: rgb(255, 91, 137);
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    transition: .3s;
}
.heart-container .checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 20;
    cursor: pointer;
}
.heart-container .svg-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heart-container .svg-outline,
.heart-container .svg-filled {
    fill: var(--heart-color);
    position: absolute;
}
.heart-container .svg-filled {
    animation: keyframes-svg-filled 1s;
    display: none;
}
.heart-container .svg-celebrate {
    position: absolute;
    animation: keyframes-svg-celebrate .5s;
    animation-fill-mode: forwards;
    display: none;
    stroke: var(--heart-color);
    fill: var(--heart-color);
    stroke-width: 2px;
}
.heart-container .checkbox:checked~.svg-container .svg-filled {
    display: block
}
.heart-container .checkbox:checked~.svg-container .svg-celebrate {
    display: block
}
@keyframes keyframes-svg-filled {
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1);
        filter: brightness(1.5);
    }
}
@keyframes keyframes-svg-celebrate {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
        filter: brightness(1.5);
    }
    100% {
        transform: scale(1.4);
        opacity: 0;
        display: none;
    }
}

.faqTab.select{
    background-color: var(--main-color2);
    color: #fff;
    
}
.faqTab{
    width: 100px;
    margin-right: 10px;
    text-align: center;
    background: #F7F7F7;
    border-radius: 50px;
    cursor: pointer;
    font-weight: bold;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* ********* 고객 > 마이페이지 ******** */
.mypagebox{
    border-radius: 16px;
    box-shadow: 0 4px 16px rgba(0,0,0,.05);
    background-color: #fff;
    padding: 20px;
    margin-bottom: 20px;
}
.mpbimg_wrap{
    overflow: hidden;
    position: relative;
    width: 100%;
    height:100%;
    margin-right: 12px;
    border-radius: 6px;
}
.mpbimg_wrap img{
    display: block;
}
.mypagebox .mpbs{
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}
.mypagebox .mpbs p{
    font-size: 1.1rem;
    font-weight: bold;
}
.mypagebox .mpbs .date{
    font-size: 0.8rem;
    color: var(--dark-gray);
}
.mypagebox .sbrn_wrap{
    display: flex;
    gap: 10px;
    margin: 20px 0 10px 0;
}
.mypagebox .sbtn{
    border: none;
    font-size: 15px;
    line-height: 21px;
    letter-spacing: -.3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    width: 100%;
    min-height: 34px;
    padding: 12px 8px 12px;
    border-radius: 6px;
    background-color: #f3f5f7;
    color: #404048;
    font-weight: bold;
    
}
.mypagebox .sbtn.r{
    color: #0B6DAA;
    background-color: #77b0d347;
}
.mypage_tabbtn{
    margin: 0;
    padding: 15px 0;
    font-size: 0.9rem;
    letter-spacing: -0.5px;
    font-weight: bold;
    cursor: pointer;
} 

.mycoupon_th{
    border-top: 2px solid #d6d6d6;
    border-bottom: 1px solid #d4d4d4;
    text-align: center;
}
.mycoupon_th .mycoupon_tr{
    padding: 13px 0;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    /* border-bottom: 1px solid #d4d4d4; */
    background: #fafafa;
    color: #666;
}
.mycoupon_td{
    font-size: 0.9rem;
    padding: 20px 0;
    font-weight: 500;
}
.mycoupon_row{
    border-bottom: 1px solid #d4d4d4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.area-over {
    overflow: hidden;
    position: relative;
    width: 100%;
}
.mypage-sub-title {
    margin: 0 auto;
    color: #4c4c4c;
    font-size: 17px;
    line-height: 22px;
    font-weight: bold;
}

.mypage-table {
    table-layout: fixed;
    width: 100%;
    max-width: 1020px;
    margin: 0 auto;
    border-top: 2px solid #d6d6d6;
}
.mypage-table tr th {
    padding: 11px 0 10px;
    background: #f6f6f6;
    color: #333;
    font-weight: bold;
    text-align: center;
}
.mypage-table tr td, 
.mypage-table tr th {
    font-size: 14px;
    line-height: 18px;
}

.mypage-table .history tr td {
    padding: 30px 10px;
    color: #333;
    text-align: center;
    border-top: 1px solid #e6e6e6;
    /* border-left: 1px solid #e6e6e6; */
}

    
/* .mypage-table .history tr:last-child td {
    border-bottom: 1px solid #e6e6e6;
} */
.lbe6{
    border-left: 1px solid #e6e6e6 !important;
}
.mypage-table tr td .area {
    overflow: hidden;
    width: 100%;
    display: flex;
    gap: 10px;
}
.mypage-table tr td .area .thum {
    width: 85px;
    height: 85px;
}
.mypage-table tr td .area .thum img {
    width: auto!important;
    max-width: 85px;
    height: auto!important;
    max-height: 85px;
}
.mypage-table tr td .area .textus {
    float: left;
    width: 66%;
    text-align: left;
}
.mypage-table tr td .area .textus .tit {
    overflow: hidden;
    height: 20px;
    color: #777;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}
.mypage-table tr td .area .textus .txt {
    overflow: hidden;
    max-height: 36px;
    color: #333;
    font-weight: 500;
}
.mypage-table tr td .color1sSize {
    display: block;
    padding: 3px 0 0;
    color: #555;
    vertical-align: top;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}
.mypage-table tr td .area .textus a span {
    overflow: hidden;
    display: inline-block;
    width: 100%;
}
.mypage-table tbody tr th {
    padding: 16px 0 16px 20px;
    background: #f6f6f6;
    color: #333;
    text-align: left;
    vertical-align: top;
    font-size: 14px;
    font-weight: bold;
}
.mypage-table tbody tr td {
    padding: 0 0 0 20px;
    color: #666;
    font-size: 14px;
    font-weight: 500;
}
.mypage-table.view tbody tr td {
    color: #333;
    padding: 0 0 0 20px;
}

.mypage-table tr td.subject {
    padding-left: 20px;
    text-align: left;
}
.mypage-table .history tr td:first-child {
    border-top: 1px solid #e6e6e6;
    border-left: 0 solid #e6e6e6;
}

.mp-search-area{
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 20px;
    background: #fafafa;
    box-sizing: border-box;
    border-radius: 5px;
}
.mp-search-area .select-area{

}
.mp-search-area .select-area p{
    float: left;
    margin-right: 20px;
    color: #666;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -.48px;
}
.mypage-step {
    overflow: hidden;
    width: 100%;
    margin-top: 10px;
    border-radius: 10px;
    background-color: #f5f5f5;
}
.mypage-step li {
    float: left;
    position: relative;
    width: 20%;
    height: 117px;
    font-size: 0;
    line-height: 0;
}

.mypage-step li em, 
.mypage-step li span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    vertical-align: top;
}
.mypage-step li em {
    top: 25px;
    color: #888;
    font-style: normal;
    font-size: 40px;
    line-height: 50px;
    font-weight: 500;
}
.msmtit{
    padding: 0 0 18px 0px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    color: #333;
    font-weight: bold;
    margin: 0;
}
.msactive{
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -.8px;
    color: var(--main-color2);
    font-weight: bold;
    padding: 0 0 15px 0px;
    cursor: pointer;

}
.msdefault{
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -.8px;
    color: #333;
    font-weight: 500;
    padding: 0 0 15px 0px;
    cursor: pointer;

}

.mylanswer{
    padding: 28px 30px 25px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 0;
    font-size: 0;

    background: #fafafa;
    border: 0;
    color: #333;
    line-height: 0;
}
.mylanswer .abox
{
    text-align: left !important;
    padding: 28px !important;
}
.mylanswer .contenta{
    border-bottom: 1px solid #e6e6e6;
    padding: 10px 0;
}
.mylanswer .contenta strong{
    color: #666;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: -0.9px;
    margin-bottom: 10px;
}   
.mylanswer .mmanswer{
    padding: 20px 0;
}
.mylanswer .mmanswer p{
    font-size: 15px;
}

.mylanswer .mmanswer strong{
    color: var(--main-color2);
    font-weight: bold;
    font-size: 1rem;
}

.mylanswer td{
    text-align: left;
    padding: 20px;
}
.openbtn{
    cursor: pointer;
}
.openbtn i{
    font-size: 20px;
}
.answerstit strong{
    display: inline-block;
    width: 70px;
    height: 22px;
    padding: 0 4px;
    border-radius: 15px;
    box-sizing: border-box;
    text-align: center;
    background: #888;
    color: #fff;
    font-size: 12px;
    line-height: 22px;
}
.answerstit.e strong{
    background: var(--main-color);
}
.mpbtn1{
    /* min-width: 75px; */
    width: auto;
    height: 32px;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #aaa;
    background: #fff;
    font-size: 12px;
    color: #666;
    font-weight: bold;
}
.status_td{
    /* display: flex;
    flex-direction: column;
    gap: 10px; */
}

.status_td p{
    font-weight: bold;
}
.status_td .date{
    font-size: 12px;
    font-weight: bold;
}

.rp2{
    text-align: right !important;
    padding-right: 20px !important;
}
.mpe_btn_area{
    text-align: center;
}
.mypage-infor-data {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 19px 0;
    border-radius: 5px;
    border: 2px solid #e6e6e6;
}
.mypage-infor-data li {
    float: left;
    padding: 0 0 0 30px;
    line-height: 0;
}
.mypage-infor-data li:first-child {
    padding: 0 30px 0 20px;
    background: 0 0;
}
.mypage-infor-data li:first-child strong {
    border-bottom: 0;
    color: #222;
}
.mypage-infor-data li strong,
.mypage-infor-data li span{
    display: inline-block;
    font-size: 14px;
    line-height: 17px;
}
.mypage-infor-data li strong {
    margin: 0 0 0 20px;
    font-weight: bold;
}
.mypage_input{
    height: 28px;
    width: 50%;
    padding: 0 0 0 10px;
    font-size: 13px;
    color: #000;
    font-weight: 500;
    vertical-align: middle;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -webkit-border-radius: 5px;
}
.mypage-address-info{
    width: 50%;
    padding: 0 0 0 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    height: 28px;
    font-size: 13px;
}
.mpd{
    font-size: 14px;
    font-weight: 500;
    line-height: 1.27;
    margin-top: 0.5rem;
    cursor: pointer;
    line-height: 1.27;
    color: #b2b8be;
    text-decoration: underline;
    text-underline-position: under;
}
.ordernum{
    color: var(--main-color2);
    font-weight: bold;
}
.mypage_w_total{
    display: inline-block;
    font-size: 14px;
    line-height: 20px;
    vertical-align: top;
    margin-bottom: 20px;
}
.mypage_w_total strong {
    display: inline-block;
    margin-right: 0;
    padding-right: 8px;
    color: #000;
    background: 0 0;
}
.mypage_w_total em {
    color: var(--main-color2);
    font-style: normal;
    font-weight: bold;
}
.wish{
    display: inline-block;
    border: none;
    background: #fff;
}
.wish img{
    width: 2rem;
    height: 2rem;
}
.vendor-info-area{
    display: flex;
    align-items: center;
    gap: 1px;
}
/* .vendor-info-area .wish {
    position: absolute;
    top: 2px;
} */

.vendor-info-area .wish img{
    width: 1.2rem;
    height: 1.2rem; 
}

.history tr td .sale {
    color: #b5b5b5;
    font-size: 12px;
    line-height: 18px;
    text-decoration: line-through;
}
.history tr td .won {
    color: var(--main-color2);
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
}
.history tr td em{
    display: inline-block;
    font-style: normal;
    font-weight: bold; 
}

.vendor_like_list img{
    width: 100%;
    height: 180px;
}

.vendor_like_list .vendor_name{
    display: inline-block;
    padding-right: 13px;
    font-size: 16px;
    color: #333;
    font-weight: bold;
    line-height: 22px;
}

/* 메인페이지 띠배너 display */
.m-banner-section-pc{
    display: block;
}
.m-banner-section-mobile{
    display: none;
}

.textus .data.review-data
{
    display: flex;
    gap: 7px;
}

.textus .data.review-data ul{
    float: left;
    font-size: 14px;
    letter-spacing: -0.9px;
    color: #888;
    font-weight: bold;
}
.textus .data.review-data li{
    padding-left: 0;
    background: 0 0;
    color: #888;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: -0.9px;
}
.textus a {
    display: block;
    margin-top: 10px;
}
.rcontent{
    margin-top: 10px;
    overflow: hidden;
    margin: 0;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    text-align: left;
}
.myorder_piwrap{
    overflow: hidden;
    position: relative;
    width: 100%;
}

.myorder_piwrap .thum {
    float: left;
    position: relative;
    width: 85px;
    height: 85px;
    margin-right: 20px;
    text-align: center;
}
.myorder_piwrap .thum img {
    width: auto!important;
    max-width: 85px;
    height: auto!important;
    max-height: 85px;
}
.myorder_piwrap .pn{
    float: left;
    width: 66%;
    text-align: left;
    
}
.myorder_piwrap .pn a {
    display: block;
}
.myorder_piwrap .pn .vendortxt{
    overflow: hidden;
    height: 20px;
    color: #777;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    display: block;
}
.myorder_piwrap .pn .pdtxt {
    overflow: hidden;
    max-height: 36px;
    color: #333;
    display: block;
}


.optiontxt,
.myorder_piwrap .pn .optiontxt {
    overflow: hidden;
    color: #777;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    display: block;
    font-style: normal;
}


.myorder_piwrap .pn .optiontxt:after {
    content: "";
    display: inline-block;
    margin: 0 5px 1px;
    width: 1px;
    height: 11px;
    background: #aaa;
    vertical-align: middle;
}

.myorder_piwrap .pn .option_list2{
    display: flex;
    /* align-items: center; */
    margin-top: 20px;
    flex-direction: column;
}
.detailModal-wrap{
    width:100%;
    height:auto;
    overflow:hidden;
    margin:0 auto;
}
.detailModal-wrap img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.rpi-wrap{
    width:55px;
    height:55px;
    overflow:hidden;
    margin:0 auto;
}
.rpi-wrap img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.mrpi-wrap{
    width: 49%;
    /* height:60px; */
    overflow:hidden;
    margin:0 auto;
}
.mrpi-wrap img{
    width:100%;
    height:100%;
    object-fit:cover;
}
.f-dc{
    display: flex;
    flex-direction: column;
}
.f-g-5{
    display: flex;
    gap: 5px;
}

/* 고객 > 마이페이지 > 주문조회 > 모바일 */
.mOrderContents{
    display: none;
}
.mOrderContents .orderData {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.mOrderContents .orderData p{
    font-size: 15px;
    font-weight: bold;
}
.mOrderContents .orderData span{
    font-size: 14px;
    color: var(--main-color2);
}
.m-myorder_piwrap{

}
.m-order-wrap{
    border: 1px solid var(--bs-gray-200);
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 0.8rem;
}
.m-prod-wrap{
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}
.m-prod-thum{
    /* flex-basis: 88px; */
    border-radius: 4px;
    overflow: hidden;
    width: 76px!important;
    height: 76px!important;
    max-height: 76px;
}
.m-prod-thum img{
    object-fit: contain;
    width: 100%!important;
    height: 100%!important;
    mix-blend-mode: multiply;
}
.m-prod-info{
    flex-grow:2;
}

.m-orderStatus{
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.95rem;
}

.m-optiontxt{
    overflow: hidden;
    color: #777;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    display: block;
    font-style: normal;
    font-size: 0.85rem;
    margin-top: 10px;
}

.m-o-price{
    font-weight: bold;
    font-size: 0.9rem;
}
.m-btn-box{
    display: flex;
    gap: 10px;
    margin: 10px 0 ;
}
.m-mpbtn1{
    min-width: 75px;
    width: auto;
    height: 32px;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid #aaa;
    background: #fff;
    font-size: 12px;
    color: #666;
    font-weight: bold;
}
.noticeText{
    color: var(--main-color);
    font-weight: 500 !important;
}
.tf500{
    font-weight: 500 !important;
    color: #000;
}
.pbd{
    
    font-size: 12px;
    font-weight: 500;
}
.claim_reason{
    color: #ff5252;
    margin-top: 1rem;
}
#chartCustom #myChart{
    height: 5000px !important;
}
#chartCustom2 #myChart{
    height: 1000px !important;
}

/* 캘린더 커스텀 */
.McontentBox .fc-event-title {
    font-weight: bold;
    margin-left: 8px;
}
.McontentBox .fc-event {
    border: none;
}