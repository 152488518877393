/*데스크탑 브라우저 가로*/
@media all and (min-width:1024px) and (max-width:1600px)  {
    .search-area{
        width: 80%;
    }
    /* [hm] 메인페이지 웨이브 */
    .wave-box{
        margin-top: -324px;
    }
    .wave-box.pc-width{
        display: block;
    }
    .wave-box.mobile-width{
        display: none;
    }
    .wave-bottom{
        margin-top: -2px;
        margin-bottom: -160px;
        background-color: #f9f9fa;
    }
    /* 메인페이지 띠배너 */
    .m-banner-section-pc{
        display: block;
    }
    .m-banner-section-mobile{
        display: none;
    }
}

/* 테블릿 세로 (해상도 768px ~ 1023px)*/
@media all and (min-width:768px) and (max-width:1024px) {
    .container {
        width: 100%;
        /* padding: 20px; */
    }
    .lnb-outer{
        padding: 0 16px;
        /* display: none; */
    }
    .lnb{
        padding: 0;
    }
    .logo-search {
        width: 100%;
    }
    .gnb{
        margin: 0;
        align-items: center;
        display: flex;
        gap: 30px;
        height: 52px;
        line-height: 52px;
    }
    .gnb a{
        cursor: pointer;
        color: #303030;
        letter-spacing: -0.02em;
        font-weight: 700;
        font-size: 14px;
        transition: all 0.3s;
    }
    .search{
        /* display: none; */
    }
    .search-area{
        width: 90%;
    }
    .mobile-gnb .row .col{
        font-weight: 600;
    }
    .carousel .simg {
        max-width: 100%;
        /* height: 320px; */
        height: auto !important;
    }
    /* [hm] 메인페이지 웨이브 */
    .wave-box{
        margin-top: -240px;
    }
    .wave-box.pc-width{
        display: block;
    }
    .wave-box.mobile-width{
        display: none;
    }
    .wave-bottom{
        margin-top: -2px;
        margin-bottom: -80px;
        background-color: #f9f9fa;
    }
    /* 메인페이지 띠배너 */
    .m-banner-section-pc{
        display: none;
    }
    .m-banner-section-mobile{
        display: block;
    }
    .modal-content.ReviewWModal{
        width: 50% !important;
        height: 80%!important;
    }

}

/*모바일(스마트폰)*/
@media (max-width : 768px)
{
    .container {
        width: 100% !important;
        padding: 16px !important;
    }
    .login_wrap{
        padding: 20px;
        width: 100%;
    }
    .logo-search {
        width: 100% !important;
    }
    .logo img{
        width:120px !important;
    }
    .lnb-outer{
        display: none;
    }
    .gnb-outer{
        display: none;
    }
    .original_header .logo img {
        width: 172px !important;
    }

    /* pd list */
    .prdList .prd-info .prd-name {
        font-size: 14px !important;
    }
    .prd-price .prd-price-discount{
        font-size: 18px !important;
    }
    .product_ListMenu .product-orderby li{
        margin-right: 5px !important;
    }

    /* 메인페이지 상품 리스트 */
    .item{margin-bottom: 20px;}
    /* .item .thumb{height: 200px;} */

    /* 사이즈 변경 시 레이아웃 순서 변경 */
    .order-xs-first{
        order: -1;
    }
    .order-xs-none{
        display: none;
    }

    .mobile-gnb{
        display: block;
        border-top: 1px #f0f0f0 solid;
        padding: 8px !important;
        text-align: center;
    }
    .mobile-gnb .row .col{
        font-weight: 600;
    }
    .mobile-gnb .menu-item{
        margin-right: 10px;
    }
    .carousel .simg {
        max-width: 100%;
        height: auto !important;
        /* height: 264px; */
    }
    
    /* 모바일 마이페이지 레이아웃 변경 */
    .mypage_wrap .mypage_side{
        display: none;
    }
    .mypage_wrap .mypage_area_sub{
        width: 100%;
    }    
    /* [hm] 메인페이지 웨이브 */
    .wave-box{
        margin-top: -240px;
    }
    .wave-box.pc-width{
        display: none;
    }
    .wave-box.mobile-width{
        display: block;
    }
    .wave-bottom{
        margin-top: -2px;
        margin-bottom: 0px;
        background-color: #f9f9fa;
    }     
    /* 장바구니 */
    .cart-vendor .vendor_name{
        font-size: 1rem;
    }
    .cart-vendor {
        padding-bottom: 9px;
    }
    
    .carttotalp{
        font-size: 1rem;
    }
    .mycoupon_box{
        flex-basis: calc(100%);
    }
    /* 메인페이지 띠배너 */
    .m-banner-section-pc{
        display: none;
    }
    .m-banner-section-mobile{
        display: block;
    }
    .modal-content.ReviewWModal{
        width: 100% !important;
        height: 80%!important;
    }
    .mypage-table tr td, 
    .mypage-table tr th {
        font-size: 12px;
        line-height: 18px;
    }
    .mpd {
        font-size: 12px;
    }
    .myorder_piwrap .thum{
        width: 50px;
        height: 50px;
        margin-right:1px;
    }
    .myorder_piwrap .thum img {
        max-width: 50px;
        max-height: 50px;
    }
    /* .mpbtn1 {
        font-size: 9px;
    } */
    .status_td p{
        font-size: 11px;
    }

    /*  */
    .mOrderContents{
        display: block;
    }
    #mypage-order-table{
        display: none;
    }
    .mypage-infor-data li {  
        padding: 0 30px 0 20px;
    }
    .mypage-table tbody tr th {
        padding: 11px 0 12px 7px;
        font-size: 13px;
    }
    .star {
        font-size: 15px;
    }
}